import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import '../../css/UserDetails.css'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { Card } from 'reactstrap'
import { CardBody, CardHeader } from 'reactstrap'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { Button } from '@mui/material'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'
function UsersAssociated() {
  const {
    background,
    tableText,
    text,
    tableBackground,
    tableheaderBackground,
  } = useTheme()
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [errormessage, setErrorMessage] = useState(false)

  const token = authToken

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  useEffect(() => {
    axios
      .get(urlEndpoints.getUsersForAccountId(accountId), {
        headers,
      })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.Message)
        console.error('Error fetching data:', error)
      })
  }, [])

  if (!errormessage) {
    return (
      <div className='users-container'>
        <Card
          style={{ background: tableheaderBackground, color: text }}
          inverse
        >
          <CardHeader
            style={{ background: tableheaderBackground, color: text }}
          >
            <div className='custom-card-head-style'>
              <h4>SFTP Users</h4>{' '}
              <Link to={urlEndpoints.navigateToNewUserForm(accountId)}>
                <Button>
                  <PersonAddAltRoundedIcon
                    style={{
                      height: '20px',
                      width: '20px',
                      verticalAlign: 'baseline',
                      background: tableheaderBackground,
                      color: text,
                    }}
                  />
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody
            style={{ background: tableheaderBackground, color: tableText }}
          >
            <table id='users'>
              <thead>
                <tr style={{ background: tableBackground, color: text }}>
                  <th style={{ background: tableBackground, color: text }}>
                    Name
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    Email
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td style={{ background: tableBackground, color: text }}>
                      {item.name}
                    </td>
                    <td style={{ background: tableBackground, color: text }}>
                      {item.email}
                    </td>
                    <td style={{ background: tableBackground, color: text }}>
                      <Link
                        to={urlEndpoints.editUserDataForAccountId(
                          accountId,
                          item,
                        )}
                      >
                        <EditRoundedIcon
                          style={{
                            color: text,
                            height: '20px',
                            width: '20px',
                            verticalAlign: 'baseline',
                          }}
                        ></EditRoundedIcon>
                      </Link>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div id='error-box'>
        <h2>{errormessage}</h2>
      </div>
    )
  }
}

export default UsersAssociated
