import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import '../../css/EditUserForm.css'
import {
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
  FormFeedback,
  Table,
} from 'reactstrap'
import Switch from 'react-switch'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'
function EditUser() {
  const {
    background,
    text,
    container,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const { accountId } = useParams()
  const [errorMessage, setErrorMessage] = useState(false)
  const { email_id } = useParams()
  const history = useNavigate()
  const { authToken } = useContext(AuthContext)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isUserEnabled, setIsUserEnabled] = useState(true)
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  }

  const fetchUserForEdit = async () => {
    try {
      const response = await axios.get(
        urlEndpoints.getUserDataWithEmailId(accountId, email_id),
        { headers },
      )
      setName(response.data.name)
      setEmail(response.data.email)
      setIsUserEnabled(response.data.active)
      setIsSignedUp(response.data.signed_up === 'true' ? true : false)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchUserForEdit()
  }, [email])

  const saveEditedUser = async () => {
    const data = {
      active: isUserEnabled,
      name: name,
      account_code: accountId,
    }
    data.account_code = String(data.account_code)
    try {
      await axios.put(
        urlEndpoints.getUserDataWithEmailId(accountId, email_id),
        data,
        { headers },
      )
      setPopupVisible(true)
      setTimeout(() => {
        setPopupVisible(false)
        history(-1)
      }, 3000)
    } catch (error) {
      if (error.response.status === 400) {
        setErrorMessage('Username cannot be empty!')
      } else {
        console.error(error.response.data.Message)
        setErrorMessage(
          `An error occurred while saving changes: ${error.response.data.Message}`,
        )
      }
    }
  }

  const handleOnFormSubmit = (e) => {
    e.preventDefault()
    saveEditedUser()
  }

  return (
    <div
      className='user-edit-form'
      style={{ background: tableheaderBackground, color: text }}
    >
      <h2>Edit User Details</h2>
      <hr />
      <Table id='users' bordered dark>
        <tbody>
          <tr>
            <td style={{ background: tableBackground, color: text }}>
              Toggle to Enable or Disable this user
            </td>
            <td style={{ background: tableBackground, color: text }}>
              <Switch
                onChange={(e) => {
                  setIsUserEnabled(!isUserEnabled)
                }}
                checked={isUserEnabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ background: tableBackground, color: text }}>
              Username
            </td>
            <td style={{ background: tableBackground, color: text }}>
              <Input
                id='username'
                name='username'
                placeholder='Username'
                type='text'
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                invalid={errorMessage}
              />
              <FormFeedback invalid>{errorMessage}</FormFeedback>
            </td>
          </tr>
          <tr>
            <td style={{ background: tableBackground, color: text }}>Email</td>
            <td style={{ background: tableBackground, color: text }}>
              <Input
                id='email'
                disabled
                name='email'
                placeholder='Email'
                type='email'
                value={email}
              />
            </td>
          </tr>
          <tr>
            <td
              colSpan='2'
              className='text-center'
              style={{ background: tableBackground, color: text }}
            >
              <ButtonGroup size='large' className='button-group'>
                {isSignedUp ? (
                  <Button
                    type='button'
                    variant='contained'
                    style={{ backgroundColor: 'green', color: 'white' }}
                  >
                    Signed Up
                  </Button>
                ) : (
                  <Button
                    type='button'
                    variant='contained'
                    style={{ backgroundColor: 'red', color: 'white' }}
                  >
                    Not Signed Up
                  </Button>
                )}
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </Table>
      <ButtonGroup className='button-group'>
        <Button
          type='submit'
          variant='contained'
          style={{
            backgroundColor: 'primary',
            color: 'white',
            marginRight: '10px',
          }}
          onClick={handleOnFormSubmit}
        >
          Save
        </Button>
        <Button
          variant='contained'
          style={{ backgroundColor: 'grey', color: 'white' }}
          onClick={() => {
            history(-1)
          }}
        >
          Discard
        </Button>
      </ButtonGroup>
      {popupVisible && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </div>
  )
}

export default EditUser
