import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { Table } from 'reactstrap'
import '../../css/AccountDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'

const ConfigurationActiveInstances = () => {
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const instanceId = urlParams.instanceId
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState({})
  const url_point = urlEndpoints.getAccountDetailsForAccountId(accountId)
  const token = authToken
  const { background, text, tableBackground, tableheaderBackground } =
    useTheme()

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  useEffect(() => {
    axios
      .get(url_point, { headers })
      .then((response) => {
        setData(response.data.activate_instances)
      })
      .catch((error) => {})
  }, [])

  function modifySummaryFiles(summaryFiles) {
    const modifiedList = summaryFiles.map((frequency) => {
      if (frequency === 'input') {
        return 'Input Summary'
      } else if (frequency === 'output') {
        return 'Output Summary'
      } else {
        return frequency
      }
    })

    return modifiedList.join(', ')
  }

  function packageOutput(packageOutput) {
    const modifiedList = packageOutput.map((frequency) => {
      if (frequency === 'status') {
        return 'Consumer Status (High, Low, Not Seen)'
      } else if (frequency === 'indicator') {
        return 'Consumer Indicator Binary (0, 1)'
      } else {
        return frequency
      }
    })

    return modifiedList.join(', ')
  }
  return (
    <div className='users-container' style={{ background, color: text }}>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Configurations</h4>{' '}
          </div>
        </CardHeader>
        <CardBody style={{ background: tableheaderBackground, color: text }}>
          <Table
            id='users'
            style={{ background, color: text }}
            bordered
            hover='true'
            dark
          >
            <>
              {Object.values(data).map((item, index) =>
                item.id === instanceId ? (
                  <React.Fragment key={index}>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Enabled
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.other && item.config.other.clientStatus
                          ? item.config.other.clientStatus === 'DISABLED'
                            ? 'False'
                            : item.config.other.clientStatus === 'ENABLED'
                              ? 'True'
                              : 'N/A'
                          : 'N/A'}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Validation
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.other && item.config.other.validation
                          ? item.config.other.validation === 'DISABLE'
                            ? 'False'
                            : item.config.other.validation === 'ENABLE'
                              ? 'True'
                              : 'N/A'
                          : 'N/A'}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Monitoring Frequency
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.other &&
                          item.config.other.monitoringFrequency && (
                            <>
                              {item.config.other.monitoringFrequency.join(', ')}
                            </>
                          )}
                      </th>
                    </tr>

                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Scores Output
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config && item.config.signal
                          ? item.config.signal.scoresOutput
                          : 'N/A'}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Package Output
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config && item.config.signal
                          ? packageOutput(item.config.signal.packageOutput)
                          : 'N/A'}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Summary Files
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.other &&
                          item.config.other.summaryFiles && (
                            <>
                              {modifySummaryFiles(
                                item.config.other.summaryFiles,
                              )}
                            </>
                          )}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Internal Alert
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config &&
                          item.config.internalAlert &&
                          item.config.internalAlert.recipients && (
                            <>
                              {item.config.internalAlert.recipients.join(', ')}
                            </>
                          )}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Industries
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.other && item.config.other.industries && (
                          <>{item.config.other.industries.join(', ')}</>
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Descriptive Data
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config && item.config.descriptiveData && (
                          <>
                            {' '}
                            {item.config.descriptiveData.lookback.join(', ')} |
                            &nbsp;
                            {item.config.descriptiveData.options.join(', ')}
                          </>
                        )}
                      </th>
                    </tr>
                  </React.Fragment>
                ) : null,
              )}
            </>
          </Table>

          <h4>Batch Append</h4>
          <Table id='users' bordered hover='true' dark>
            <>
              {Object.values(data).map((item, index) =>
                item.id === instanceId ? (
                  <React.Fragment key={index}>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Enabled{' '}
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.batchAppends &&
                        item.config.batchAppends.clientStatus
                          ? item.config.batchAppends.clientStatus === 'DISABLED'
                            ? 'False'
                            : item.config.batchAppends.clientStatus ===
                                'ENABLED'
                              ? 'True'
                              : 'N/A'
                          : 'N/A'}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Append Type{' '}
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.config.batchAppends &&
                        item.config.batchAppends.appendType
                          ? item.config.batchAppends.appendType
                          : 'N/A'}
                      </th>
                    </tr>
                  </React.Fragment>
                ) : null,
              )}
            </>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default ConfigurationActiveInstances
