import React, { useEffect, useState, useContext } from 'react'
import { useTheme } from './ThemeContext'
import ActiveInstances from './sub_components/ActiveInstances'
import Append from './sub_components/Append'
import { useParams, useLocation } from 'react-router-dom'
import AccountDetails from './sub_components/AccountDetails'
import UsersAssociated from './sub_components/UserDetails'
import '../css/AccountInfo.css'
import { Container, Row, Col } from 'reactstrap'
import { AuthContext } from '../auth/AuthProvider'
import axios from 'axios'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
export default function AccountInfo() {
  const location = useLocation()
  const { background, text, tableheaderBackground } = useTheme()
  const queryParams = new URLSearchParams(location.search)
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const salesforceId = urlParams.salesforceId
  const [selectedComponent, setSelectedComponent] = useState('append')
  const [companyName, setCompanyName] = useState('')
  const { authToken } = useContext(AuthContext)
  const [errormessage, setErrormessage] = useState('')

  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken,
        }

        const response = await axios.get(urlEndpoints.getAccountCustomer, {
          headers,
        })
        const accounts = response.data

        const account = accounts.find((account) => account.code === accountId)
        if (account) {
          setCompanyName(account.company)
        } else {
          setCompanyName('Company not found')
        }
      } catch (error) {
        setErrormessage(error.message)
        console.error('Error:', error.message)
      }
    }

    if (accountId) {
      fetchCompanyName()
    }
  }, [accountId])
  const handleChange = (event) => {
    setSelectedComponent(event.target.value)
  }

  return (
    <div
      className='specific-account-details'
      style={{ background, color: text }}
    >
      <Container style={{ background, color: text }}>
        <Row>
          <Col className='title-segment'>
            <h3>{companyName}</h3> <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col className='headers'>
            <div className='select-options dark-select-container'>
              <select
                className='dark-select'
                value={selectedComponent}
                onChange={handleChange}
                style={{ background: tableheaderBackground, color: text }}
              >
                <option
                  value='append'
                  style={{ background: tableheaderBackground, color: text }}
                >
                  Append Instances
                </option>
                <option
                  value='activeInstances'
                  style={{ background: tableheaderBackground, color: text }}
                >
                  Activate Instances
                </option>
              </select>
            </div>
          </Col>
        </Row>
        <Row style={{ background, color: text }}>
          <Col className='headers' style={{ background, color: text }}>
            <AccountDetails accountId={accountId}></AccountDetails>
          </Col>
          <Col className='headers' style={{ background, color: text }}>
            <UsersAssociated></UsersAssociated>
          </Col>
          <Col className='headers' style={{ background, color: text }}>
            {selectedComponent === 'append' ? (
              <Append
                salesforceId={salesforceId}
                accountCode={accountId}
                companyName={companyName}
                style={{ background, color: text }}
              />
            ) : (
              <ActiveInstances
                salesforceId={salesforceId}
                accountCode={accountId}
                companyName={companyName}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
