import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Input,
} from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import '../../css/AppendJobDetails.css'
import { AuthContext } from '../../auth/AuthProvider'
import { useTheme } from '../../components/ThemeContext'

const OutputConfiguration = ({ modal, toggle, jobId }) => {
  const {
    background,
    text,
    root,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const [activeOutputTab, setActiveOutputTab] = useState('defaultLayout')
  const { authToken } = useContext(AuthContext)
  const token = authToken
  const [defaultLayoutDropdownOpen, setDefaultLayoutDropdownOpen] =
    useState(false)
  const [setDefaultLayoutSelectedOptions] = useState([])
  const [customLayoutDropdownOpen, setCustomLayoutDropdownOpen] =
    useState(false)
  const [customLayoutSelectedOptions, setCustomLayoutSelectedOptions] =
    useState([])
  const [searchTerm] = useState('')
  const [selectedHeaders, setSelectedHeaders] = useState([])
  const [headerdata, setHeaderData] = useState([])
  const [markedHeaders, setMarkedHeaders] = useState([])
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [fileFormats, setFileFormats] = useState('')
  const [delimiters, setDelimiters] = useState('')
  const [customDelimiter, setCustomDelimiter] = useState([])
  const [customFileFormat, setCustomFileFormat] = useState([])
  const [changesSaved, setChangesSaved] = useState(false)
  const [defaultButton, setDefaultButton] = useState(false)

  const options = [
    { value: 'name', label: 'Name' },
    { value: 'record_id', label: 'Record ID' },
    { value: 'email', label: 'Email' },
  ]

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    fetch(urlEndpoints.getFileFormatDelimiter, {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        setCustomDelimiter(data.file_delimiter)
        setCustomFileFormat(data.file_format)
      })
      .catch((error) => console.error('Error fetching data:', error.message))
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getOutputConfigHeaders(jobId),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        setData(response.data.info_attributes.headers)
        setFileFormats(response.data.info_attributes.file_format || 'CSV')
        setDelimiters(response.data.info_attributes.file_delimiter || 'COMMA')
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getOutputHeaderDetails(jobId),
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )

        if (!response.data) {
          throw new Error('No data received')
        }
        setSelectedHeaders(response.data.info_attributes.headers)
        setDefaultButton(response.data.info_attributes.input_file === 'false')
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    }

    fetchData()
  }, [jobId, authToken])

  const handleCheckboxChange = (key) => {
    setData((prevData) => ({
      ...prevData,
      [key]: !prevData[key],
    }))
  }
  const handleDropdownDelimiter = (e) => {
    setDelimiters(e.target.value)
  }
  const handleDropdownFileFormat = (e) => {
    setFileFormats(e.target.value)
  }
  const handleHeaderSelection = (e) => {
    const value = e.target.value

    if (selectedHeaders.includes(value)) {
      setSelectedHeaders(selectedHeaders.filter((header) => header !== value))
    } else {
      setSelectedHeaders([...selectedHeaders, value])
    }
  }

  const default_fileout_delimiter = async (secondRequestBody, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postNotification(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(secondRequestBody),
      })
        .then((secondResponse) => {
          if (!secondResponse.ok) {
            throw new Error(
              `Second request failed with status: ${secondResponse.status}`,
            )
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => {
            setChangesSaved(false)
            toggle()
          }, 5000)
        })
        .catch((secondError) =>
          console.error('Error in second request:', secondError.message),
        )
    })
  }
  const default_fileout_headers = async (formdata, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postOutputConfigStatus(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formdata),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`)
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => {
            setChangesSaved(false)
            toggle()
          }, 5000)
        })
        .catch((error) =>
          console.error('Error submitting default layout:', error.message),
        )
    })
  }
  const handleDefaultLayoutSubmit = async () => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const headers_value = Object.keys(data)

    const selectedFields = Object.entries(data)
      .filter(([key, value]) => value)
      .map(([key]) => key)

    const formdata = defaultLayoutDropdownOpen
      ? { selected_fields: selectedFields }
      : { selected_fields: headers_value }

    const response = await default_fileout_headers(formdata, headers)
    const secondRequestBody = {
      file_format: 'CSV',
      file_delimiter: 'COMMA',
    }
    const response2 = await default_fileout_delimiter(
      secondRequestBody,
      headers,
    )

    if (response && response2) {
      setLoading(false)
      window.location.reload(true)
    }
  }

  const custom_fileout_delimiter = async (secondRequestBody, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postNotification(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(secondRequestBody),
      })
        .then((secondResponse) => {
          if (!secondResponse.ok) {
            return secondResponse.json().then((error) => {
              throw new Error(` Message: ${error.Message}`)
            })
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => {
            setChangesSaved(false)
            toggle()
          }, 5000)
        })
        .catch((secondError) => {
          console.error('Error in second request:', secondError.message)
          window.alert(`Error: ${secondError.message}`)
        })
    })
  }

  const custom_fileout_headers = async (formdata, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postOutputConfigStatus(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formdata),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((error) => {
              throw new Error(` Message: ${error.Message}`)
            })
          }
          resolve(true)
        })
        .catch((error) => {
          console.error('Error submitting custom layout:', error.message)
          window.alert(`Error: ${error.message}`)
        })
    })
  }

  const handleCustomLayoutSubmit = async () => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const selectedFields = customLayoutDropdownOpen
      ? Object.entries(data)
          .filter(([key, value]) => value)
          .map(([key]) => key)
      : Object.keys(data)

    const formdata = { selected_fields: selectedFields }

    const response = await custom_fileout_headers(formdata, headers)

    const secondRequestBody = {
      file_format: fileFormats,
      file_delimiter: delimiters,
    }

    const response2 = await custom_fileout_delimiter(secondRequestBody, headers)
    if (response && response2) {
      setLoading(false)
      window.location.reload(true)
    }
  }

  const toggleTab = (tab) => {
    if (activeOutputTab !== tab) setActiveOutputTab(tab)
  }

  const handleButtonClick = (layout) => {
    if (layout === 'defaultLayout') {
      setDefaultLayoutDropdownOpen(!defaultLayoutDropdownOpen)
    } else if (layout === 'customLayout') {
      setCustomLayoutDropdownOpen(!customLayoutDropdownOpen)
    }
  }

  const handleOptionToggle = (layout, value) => {
    if (layout === 'defaultLayout') {
      setDefaultLayoutSelectedOptions((prevOptions) =>
        prevOptions.includes(value)
          ? prevOptions.filter((option) => option !== value)
          : [...prevOptions, value],
      )
    } else if (layout === 'customLayout') {
      setCustomLayoutSelectedOptions((prevOptions) =>
        prevOptions.includes(value)
          ? prevOptions.filter((option) => option !== value)
          : [...prevOptions, value],
      )
    }
  }

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const handleSelectAll = (layout) => {
    const allValues = options.map((option) => option.value)
    if (layout === 'defaultLayout') {
      setDefaultLayoutSelectedOptions(allValues)
    } else if (layout === 'customLayout') {
      setCustomLayoutSelectedOptions(allValues)
    }
  }

  return (
    <>
      <style>
        {`
          .custom-select {
            background-color: #333; 
            color: #fff; 
          }
        `}
      </style>
      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          background: tableheaderBackground,
          color: text,
          maxWidth: '60%',
        }}
      >
        <ModalHeader
          style={{ background: tableheaderBackground, color: text }}
          toggle={toggle}
        >
          Output File Configuration
        </ModalHeader>
        <ModalBody style={{ background: root, color: text }}>
          <Nav style={{ background: root, color: text }} tabs>
            <NavItem>
              <NavLink
                className={activeOutputTab === 'defaultLayout' ? 'active' : ''}
                onClick={() => toggleTab('defaultLayout')}
              >
                Default Layout
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeOutputTab === 'customLayout' ? 'active' : ''}
                onClick={() => toggleTab('customLayout')}
              >
                Custom Layout
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            style={{ background: root, color: text }}
            activeTab={activeOutputTab}
          >
            <TabPane
              style={{ background: root, color: text }}
              tabId='defaultLayout'
            >
              {/* Default layout content */}
              <br />
              <p>
                By default, the file will be returned in the following format.
                <br /> For changes to this layout, configure a customer output.
              </p>
              <div>
                <Table id='users' bordered dark>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        File Format
                      </th>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        CSV
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        File Delimiter
                      </th>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        COMMA
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        Headers
                      </th>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        Append input columns&nbsp;
                        <button
                          onClick={() => handleButtonClick('defaultLayout')}
                          disabled={defaultButton}
                        >
                          {defaultLayoutDropdownOpen ? 'OFF' : 'ON'}
                        </button>
                        <br />
                        Select the Unique Identifier to return:
                        {defaultLayoutDropdownOpen && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                border: '1px solid #ccc',
                                width: '200px',
                                height: '200px',
                                overflowY: 'scroll',
                                padding: '10px',
                              }}
                            >
                              {Object.entries(data).map(([key, value]) => (
                                <div key={key}>
                                  <label>
                                    <input
                                      type='checkbox'
                                      checked={value}
                                      onChange={() => handleCheckboxChange(key)}
                                    />
                                    {key}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <br />
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </TabPane>
            <TabPane
              style={{ background: root, color: text }}
              tabId='customLayout'
            >
              {/* Custom layout content */}
              <br />
              <p>
                Click below to configure a custom layout for the customer. Note
                that the customer must follow the configured layout for all
                future uploads, unless updated by an admin.
              </p>
              <div>
                <Table id='users' bordered dark>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        File Format
                      </th>

                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        <select
                          value={fileFormats}
                          onChange={handleDropdownFileFormat}
                          style={{
                            height: '45px',
                            width: '100%',
                            borderRadius: '8px',
                            backgroundColor: container,
                            color: text,
                            border: `1px solid ${root}`,
                          }}
                        >
                          {customFileFormat.map((fileformat, index) => (
                            <option key={index} value={fileformat}>
                              {fileformat}
                            </option>
                          ))}
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        File Delimiter
                      </th>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        <select
                          value={delimiters}
                          onChange={handleDropdownDelimiter}
                          style={{
                            height: '45px',
                            width: '100%',
                            borderRadius: '8px',
                            backgroundColor: container,
                            color: text,
                            border: `1px solid ${root}`,
                          }}
                        >
                          {customDelimiter.map((delimiter, index) => (
                            <option key={index} value={delimiter}>
                              {delimiter}
                            </option>
                          ))}
                        </select>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        Headers
                      </th>
                      <th
                        style={{
                          background: container,
                          color: text,
                          padding: '10px',
                          border: `2px solid ${root}`,
                          width: '50%',
                          height: '80px',
                        }}
                      >
                        Append input columns&nbsp;
                        <button
                          onClick={() => handleButtonClick('customLayout')}
                          disabled={defaultButton}
                        >
                          {customLayoutDropdownOpen ? 'OFF' : 'ON'}
                        </button>
                        <br />
                        Select the Unique Identifier to return:
                        {customLayoutDropdownOpen && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                border: '1px solid #ccc',
                                width: '200px',
                                height: '200px',
                                overflowY: 'scroll',
                                padding: '10px',
                              }}
                            >
                              {Object.entries(data).map(([key, value]) => (
                                <div key={key}>
                                  <label>
                                    <input
                                      type='checkbox'
                                      checked={value}
                                      onChange={() => handleCheckboxChange(key)}
                                    />
                                    {key}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <br />
                        Note: full header details are based on the configured to
                        be appended.
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          {activeOutputTab === 'defaultLayout' && (
            <>
              {defaultButton && (
                <div style={{ color: 'red' }}>
                  *Please complete the Input File Configuration before
                  configuring the Output Section
                </div>
              )}

              <Button
                color='primary'
                onClick={handleDefaultLayoutSubmit}
                disabled={defaultButton}
              >
                {loading ? 'Submitting...' : 'Submit for Default Layout'}
              </Button>
              <Button color='secondary' onClick={toggle}>
                Cancel
              </Button>
            </>
          )}
          {activeOutputTab === 'customLayout' && (
            <>
              {defaultButton && (
                <div style={{ color: 'red' }}>
                  *Please complete the Input File Configuration before
                  configuring the Output Section
                </div>
              )}
              <Button
                color='primary'
                onClick={handleCustomLayoutSubmit}
                disabled={defaultButton}
              >
                {loading ? 'Submitting...' : 'Submit for Custom Layout'}
              </Button>
              <Button color='secondary' onClick={toggle}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default OutputConfiguration
