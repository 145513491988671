import React from 'react'
import { Link } from 'react-router-dom'
import '../css/SideBar.css'
import { IconButton } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import GroupIcon from '@mui/icons-material/Group'
import { useContext } from 'react'
import { AuthContext } from '../auth/AuthProvider'
import { useTheme } from './ThemeContext'

function SideBar() {
  const { darkMode, background, text } = useTheme()
  const { oktaAuth } = useContext(AuthContext)

  const sidebarBackground = darkMode ? '#1a1d1f' : '#808080'
  const handleLogout = async () => {
    try {
      const confirmLogout = window.confirm('Are you sure you want to log out?')

      if (confirmLogout) {
        const logoutUrl = await oktaAuth.signOut({
          clearTokensBeforeRedirect: true,
          postLogoutRedirectUri: process.env.REACT_APP_OKTA_LOGOUT_URL,
        })

        setTimeout(() => {
          window.location.href = logoutUrl
        }, 6000)
      }
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }
  return (
    <div style={{ background: sidebarBackground, color: text }}>
      <div className='sidebar-icons'>
        <nav className='sidebar-nav'>
          <ul className='sidebar-ul'>
            <IconButton
              className='white-icon-button'
              disableRipple={true}
              to='/oidc/callback/'
              component={Link}
            >
              <HomeIcon style={{ color: text }} />
              <span style={{ color: text }}> Home</span>
            </IconButton>
            <br />
            <IconButton
              className='white-icon-button'
              disableRipple={true}
              to='/oidc/callback/accounts'
              component={Link}
            >
              <ManageAccountsIcon style={{ color: text }} />
              <span style={{ color: text }}> Accounts</span>
            </IconButton>
            <br />
            <IconButton
              className='white-icon-button'
              disableRipple={true}
              to='/oidc/callback/jobs'
              component={Link}
            >
              <CheckCircleOutlineIcon style={{ color: text }} />
              <span style={{ color: text }}> Job Runs</span>
            </IconButton>
            <br />

            <IconButton
              className='white-icon-button'
              disableRipple={true}
              to='/oidc/callback/users'
              component={Link}
            >
              <GroupIcon style={{ color: text }} />
              <span style={{ color: text }}> Users</span>
            </IconButton>
            <br />
            <IconButton
              className='white-icon-button'
              disableRipple={true}
              to='/oidc/callback/billing'
              component={Link}
            >
              <ReceiptLongIcon style={{ color: text }} />
              <span style={{ color: text }}> Billing</span>
            </IconButton>
            <br />
            <br />
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default SideBar
