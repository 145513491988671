import React, { useState, useContext, useEffect } from 'react'
import '../css/AccountsList.css'
import { AuthContext } from '../auth/AuthProvider'
import axios from 'axios'
import { InfinitySpin } from 'react-loader-spinner'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { FormGroup, Input, Form, Label, Col, Row, Button } from 'reactstrap'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
import { useTheme } from './ThemeContext'

function AccountsList() {
  const [searchTerm, setSearchTerm] = useState('')
  const {
    background,
    text,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const columns = [
    {
      name: 'Company',
      selector: (row) => row.company,
      sortable: true,
      sortFunction: (a, b) => a.company.localeCompare(b.company),
      center: true,
      cell: (row) => (
        <Link
          to={urlEndpoints.getAccountInfoPage(
            row.code,
            row.salesforce_id,
            row.company,
          )}
          className='account-link'
        >
          {row.company}
        </Link>
      ),
    },
    {
      name: 'Code',
      selector: (row) => row.code,
      center: true,
    },
    {
      name: 'Product',
      center: true,
      selector: (row) => Object.keys(row.products).join(', '),
    },
  ]

  const [data, setData] = useState(null)
  const [errormessage, setErrormessage] = useState(null)
  const { authToken } = useContext(AuthContext)

  const fetchData = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      }

      const response = await axios.get(urlEndpoints.getAccountCustomer, {
        headers,
      })
      const sortedData = response.data.sort((a, b) =>
        a.company.localeCompare(b.company),
      )
      setData(sortedData)
    } catch (error) {
      setErrormessage(error.message)
      console.error('Error:', error.message)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const filteredData = data?.filter((row) => {
    const companyMatches = row.company
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    const codeMatches = row.code
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    const productsMatch = Object.keys(row.products)
      .join(', ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())

    return companyMatches || codeMatches || productsMatch
  })

  if (data) {
    return (
      <div
        className='accounts-list'
        style={{ background, color: text, height: '100vh' }}
      >
        <h2>All Accounts</h2>
        <hr></hr>
        <Form>
          {' '}
          <FormGroup>
            <Row className='align-items-center'>
              {' '}
              {/* This classname is required to overwrite */}
              <Label for='exampleEmail2' sm={2} size='lg'>
                Search Here
              </Label>
              <Col sm={7}>
                <Input
                  className='search-input-box'
                  type='search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault()
                    }
                  }}
                />
              </Col>
              <Col style={{ background, color: text }}>
                <Link to={urlEndpoints.createNewAccountForm}>
                  <Button
                    style={{
                      background: tableheaderBackground,
                      color: tableText,
                    }}
                  >
                    <h5>Create New Account </h5>
                  </Button>
                </Link>
              </Col>
            </Row>
          </FormGroup>
        </Form>

        <DataTable
          className='data-wrapper'
          columns={columns}
          data={filteredData}
          direction='auto'
          fixedHeaderScrollHeight='300px'
          pagination
          responsive
          customStyles={{
            headRow: {
              style: {
                background: containerHeader,
                color: tableText,
              },
            },
            headCells: {
              style: {
                background: containerHeader,
                color: tableText,
              },
            },
            rows: {
              style: {
                background: container,
                color: tableText,
              },
            },
            cells: {
              style: {
                background: container,
                color: tableText,
              },
            },
            pagination: {
              style: {
                background: containerHeader,
                color: tableText,
              },
            },
          }}
        />
      </div>
    )
  }

  if (errormessage) {
    return (
      <div className='errors'>
        <h2>Failed to fetch data from backend API</h2>
        <hr />
        <h3>{errormessage}</h3>
        <h4>
          It is very likely that your token is expired or check if your browser
          has CORS enabled
        </h4>
      </div>
    )
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <InfinitySpin width='200' color='#FFA500' />
      </div>
    )
  }
}

export default AccountsList
