import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useParams, useNavigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Table, Button } from 'reactstrap'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import '../../css/EditAppendJobDetails.css'
import { useTheme } from '../../components/ThemeContext'

const EditAppendJobDetails = () => {
  const {
    background,
    text,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const jobId = urlParams.jobId
  const history = useNavigate()
  const { username, authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [enteredEmail, setEnteredEmail] = useState('')
  const [emailList, setEmailList] = useState([])
  const [productNames, setProductNames] = useState([])
  const [changesSaved, setChangesSaved] = useState(false)
  const token = authToken
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  }

  const fetchAccountForEdit = async () => {
    try {
      const url_endpoint = urlEndpoints.getAccountDetailsForAccountId(accountId)
      const response = await axios.get(url_endpoint, { headers })
      let identity_products = response.data.products.identity || []
      let attribute_products = response.data.products.attributes || []
      let combined_products = [...identity_products, ...attribute_products]
      setProductNames(combined_products)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchAccountForEdit()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getAppendInstancesDetails(accountId, jobId),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setData(response.data)
        setEmailList(response.data[0]?.notifications || [])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [accountId, jobId, token])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getAppendInstancesDetailNameandEmail(accountId, jobId),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setName(response.data[0]?.name || '')
        setSelectedOption(response.data[0]?.selected_product || '')
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleEmailInputChange = (e) => {
    setEnteredEmail(e.target.value)
  }

  const handleListEmails = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (enteredEmail.trim() !== '') {
      if (emailRegex.test(enteredEmail.trim())) {
        setEmailList([...emailList, enteredEmail])
        setEnteredEmail('')
      } else {
        alert('Please enter a valid email address!')
      }
    } else {
      alert('Email address cannot be empty!!')
    }
  }

  const handleDeleteEmail = (index) => {
    const updatedEmailList = [...emailList]
    updatedEmailList.splice(index, 1)
    setEmailList(updatedEmailList)
  }

  const handleSave = async () => {
    try {
      const formData = {
        name: name,
        product: selectedOption,
        username: username,
      }

      if (name.length < 1) {
        alert('Name should not be empty!!! ')
        return
      }
      if (name.includes('/')) {
        alert('Please do not include the "/" character in the name.')
        return
      }

      await axios.put(
        urlEndpoints.editAppendToAccount(accountId, jobId),
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )

      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error.response?.data?.Message || 'An error occurred')
    }
  }

  const handleNotificationSave = async () => {
    try {
      const formData = {
        delivery_notification: emailList,
      }

      await axios.post(urlEndpoints.postNotification(jobId), formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })

      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  return (
    <div className='edit-table'>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Edit Append Job</h4>
          </div>
        </CardHeader>
        <CardBody
          style={{ background: tableheaderBackground, color: tableText }}
        >
          <Table id='users' bordered dark>
            <tbody>
              <tr className='lables'>
                <th style={{ background: tableBackground, color: text }}>
                  Name
                </th>
                <th style={{ background: tableBackground, color: text }}>
                  <input
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      height: '45px',
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </th>
              </tr>
              <tr>
                <th style={{ background: tableBackground, color: text }}>
                  Product Selection
                </th>
                <th style={{ background: tableBackground, color: text }}>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    style={{
                      height: '45px',
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    {productNames.map((companyName) => (
                      <option key={companyName} value={companyName}>
                        {companyName}
                      </option>
                    ))}
                  </select>
                </th>
              </tr>
            </tbody>
          </Table>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              onClick={handleSave}
              color='primary'
              className='mr-2'
              style={{ marginRight: '10px' }}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              className='form-button'
              onClick={() => {
                history(-1)
              }}
            >
              Discard
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card
        style={{ background: tableheaderBackground, color: text }}
        inverse
        className='mt-4'
      >
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Edit Notifications</h4>
          </div>
        </CardHeader>
        <CardBody
          style={{ background: tableheaderBackground, color: tableText }}
        >
          <Table id='users' bordered dark>
            <thead>
              <tr className='lables'>
                <th style={{ background: tableBackground, color: text }}>
                  Email
                </th>
                <th style={{ background: tableBackground, color: text }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {emailList.map((email, index) => (
                <tr key={index}>
                  <td style={{ background: tableBackground, color: text }}>
                    {email}
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    <IconButton
                      color='primary'
                      onClick={() => handleDeleteEmail(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ background: tableBackground, color: text }}>
                  <input
                    type='text'
                    value={enteredEmail}
                    onChange={handleEmailInputChange}
                  />
                </td>
                <td style={{ background: tableBackground, color: text }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleListEmails}
                  >
                    +
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              onClick={handleNotificationSave}
              color='primary'
              className='mr-2'
              style={{ marginRight: '10px' }}
              disabled={emailList.length === 0}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              className='form-button'
              onClick={() => {
                history(-1)
              }}
            >
              Discard
            </Button>
          </div>
        </CardBody>
      </Card>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </div>
  )
}

export default EditAppendJobDetails
