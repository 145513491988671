import React from 'react'
import '../css/Login.css'
import loginImage from '../static_assets/login-image.jpg'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
const Login = () => {
  const handleLogin = () => {
    window.location.href = urlEndpoints.baseLoginPage
  }

  return (
    <div className='login-container'>
      <div className='login-image'>
        <img src={loginImage} alt='Login' />
      </div>
      <div className='login-details'>
        <h1>Welcome to Appends Admin</h1>
        <h2>Login using OKTA</h2>
        <hr></hr>
        <button onClick={handleLogin} className='login-button'>
          Login
        </button>
      </div>
    </div>
  )
}
export default Login
