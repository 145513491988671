import React from 'react'
import '../css/ProjectSetup.css'

function ProjectSetup() {
  return (
    <div id='project-setup'>
      <h2>This is project setup </h2>
      <h3>Work in progress</h3>
    </div>
  )
}

export default ProjectSetup
