import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import AppendJobDetails from './AppendJobDetails'
import AppendJobStatus from './AppendJobStatus'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import axios from 'axios'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'

export default function AppendDetails() {
  const urlParams = useParams()
  const accountId = urlParams.accountName
  const jobId = urlParams.jobid
  const accountCode = urlParams.accountCode
  const salesforceId = urlParams.accountName
  const location = useLocation()
  const companyName = location.state.companyName
  const [errorMessage, setErrorMessage] = useState('')

  const [matchingAppend, setMatchingAppend] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getAppendList(accountCode),
        )
        const matchingAppend = response.data.find(
          (item) => item.job_id === jobId,
        )
        if (matchingAppend) {
          setMatchingAppend(matchingAppend)
        }
      } catch (error) {
        setErrorMessage(error.response.data.Message)
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [jobId, accountCode])

  return (
    <div className='specific-account-details'>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <Link
              to={urlEndpoints.getAccountInfoPage(
                accountCode,
                salesforceId,
                companyName,
              )}
              className='account-link'
            >
              {companyName}
            </Link>
            <ChevronRightIcon style={{ margin: '0 5px' }} />
            {matchingAppend?.append_name}
          </Col>
          <Col xs='auto' className='title-segment'></Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className='headers'>
            <AppendJobDetails
              accountId={accountId}
              jobId={jobId}
              accountCode={accountCode}
            />
          </Col>
          <Col>
            <Row>
              <AppendJobStatus
                accountId={accountId}
                jobId={jobId}
                accountCode={accountCode}
              />
            </Row>
          </Col>
        </Row>
      </Container>
      {errorMessage && <p className='error-message'>{errorMessage}</p>}
    </div>
  )
}
