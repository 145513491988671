import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import '../../css/AccountDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { useTheme } from '../../components/ThemeContext'
const AccountDetails = (props) => {
  const { background, text, tableBackground, tableheaderBackground } =
    useTheme()
  const accountId = props.accountId
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState({})
  const token = authToken
  const [errormessage, setErrorMessage] = useState(false)
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  useEffect(() => {
    axios
      .get(urlEndpoints.getAccountDetailsForAccountId(accountId), { headers })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.Message)
      })
  }, [])

  const productNames = data.products ? Object.keys(data.products) : []
  const products = data.products || {}
  const guardian = products.guardian || {}
  const guardianServices = guardian.services || []
  const activate = products.activate || {}
  const activateJourneys = activate.journeys || []
  const activateServices = activate.services || []
  const identity = products.identity || []
  const attributes = products.attributes || []
  const valid_firstparty = []
  if (activateServices.includes('first_party')) {
    valid_firstparty.push('Enabled')
  } else {
    valid_firstparty.push('Disabled')
  }
  if (!errormessage) {
    return (
      <div className='users-container' style={{ background, color: text }}>
        <Card style={{ background: tableheaderBackground, color: text }}>
          <CardHeader
            style={{ background: tableheaderBackground, color: text }}
          >
            <div className='custom-card-head-style'>
              <h4>Account Details</h4>{' '}
              <Link to={urlEndpoints.navigateToEditAccountDetails(accountId)}>
                <BorderColorIcon
                  style={{
                    height: '20px',
                    width: '20px',
                    verticalAlign: 'baseline',
                    background: tableheaderBackground,
                    color: text,
                  }}
                />
              </Link>
            </div>
          </CardHeader>
          <CardBody style={{ background: tableheaderBackground, color: text }}>
            <table
              id='account-details-table'
              hover='true'
              style={{ background, color: text }}
            >
              <tbody>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Code
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {data.code}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Company
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {data.company}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    {' '}
                    Trayio ID
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {data.trayio_user_id}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Salesforce ID
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {data.salesforce_id}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Products
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {productNames.map((productName, index) => (
                      <div key={index}>{productName}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Identity
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {identity.map((identity, index) => (
                      <div key={index}>{identity}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Attributes
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {attributes.map((attributes, index) => (
                      <div key={index}>{attributes}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Activate 1st Party Journeys
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {valid_firstparty.map((valid, index) => (
                      <div key={index}>{valid}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Available Activate Journeys
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {activateJourneys.map((activateJourney, index) => (
                      <div key={index}>{activateJourney}</div>
                    ))}
                  </td>
                </tr>

                <tr>
                  <td style={{ background: tableBackground, color: text }}>
                    Available Guardian Services
                  </td>
                  <td style={{ background: tableBackground, color: text }}>
                    {guardianServices.map((guardianService, index) => (
                      <div key={index}>{guardianService}</div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div id='error-box'>
        <h2>{errormessage}</h2>
      </div>
    )
  }
}
export default AccountDetails
