import React, { useContext, useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Switch from '@mui/material/Switch'
import { useTheme } from '../../components/ThemeContext'
import '../../css/AppendJobDetails.css'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import axios from 'axios'

const PreProcessing = ({ modal, toggle, jobId }) => {
  const { authToken } = useContext(AuthContext)
  const [defaultValues, setDefaultValues] = useState(null)
  const [signalToggle, setSignalToggle] = useState(false)
  const [isEncryptToggled, setIsEncryptToggled] = useState(false)
  const [selectedOption, setSelectedOption] = useState('zip')
  const [changesSaved, setChangesSaved] = useState(false)
  const {
    background,
    text,
    root,
    tableBackground,
    tableText,
    tableheaderBackground,
    container,
    containerHeader,
  } = useTheme()

  useEffect(() => {
    const fetchDefaultValues = async () => {
      try {
        const response = await axios.get(urlEndpoints.getPreProcessing(jobId), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        setDefaultValues(response.data)
      } catch (error) {
        console.error('Error fetching default values:', error)
        alert(error)
      }
    }

    fetchDefaultValues()
  }, [jobId, authToken])

  useEffect(() => {
    if (defaultValues) {
      setSignalToggle(defaultValues.info_attributes?.decompress === 'True')
      setIsEncryptToggled(defaultValues.info_attributes?.decrypt === 'True')
      setSelectedOption(
        defaultValues.info_attributes?.decompression_type || 'zip',
      )
    }
  }, [defaultValues])

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleDefaultLayoutSubmit = async () => {
    try {
      let formData = {
        decrypt: isEncryptToggled ? 'True' : 'False',
        decompress: signalToggle ? 'True' : 'False',
      }

      if (!signalToggle) {
        formData.decompression_type = 'none'
      } else if (selectedOption !== 'none') {
        formData.decompression_type = selectedOption
      } else {
        formData.decompression_type = 'zip'
      }

      await axios.post(urlEndpoints.postPreProcessing(jobId), formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })

      setChangesSaved(true)
      setTimeout(() => setChangesSaved(false), 5000)
      toggle()
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  const handleSignalToggle = () => {
    setSignalToggle(!signalToggle)
  }

  const handleEncryptToggle = () => {
    setIsEncryptToggled(!isEncryptToggled)
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size='lg'
        scrollable={true}
        backdrop='static'
        style={{
          maxWidth: '60%',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <ModalHeader
          style={{ background: tableheaderBackground, color: text }}
          toggle={toggle}
        >
          Pre Processing
        </ModalHeader>
        <ModalBody
          style={{ background: root, color: text }}
          className='dark-mode-modal-body'
        >
          <hr />

          <h4>Attributes</h4>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              <tr>
                <td
                  style={{
                    background: container,
                    color: text,
                    padding: '10px',
                    border: `2px solid ${root}`,
                  }}
                >
                  <h5 style={{ color: text }}>Decrypt</h5>
                </td>
                <td
                  style={{
                    background: container,
                    color: text,
                    padding: '10px',
                    border: `2px solid ${root}`,
                  }}
                >
                  <Switch
                    onChange={handleEncryptToggle}
                    checked={isEncryptToggled}
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: container,
                    color: text,
                    padding: '10px',
                    border: `2px solid ${root}`,
                  }}
                >
                  <h5 style={{ color: text }}>Decompress</h5>
                </td>
                <td
                  style={{
                    background: container,
                    color: text,
                    padding: '10px',
                    border: `2px solid ${root}`,
                  }}
                >
                  <Switch
                    onChange={handleSignalToggle}
                    checked={signalToggle}
                  />
                </td>
              </tr>
              {signalToggle && (
                <tr>
                  <td
                    style={{
                      background: container,
                      color: text,
                      padding: '10px',
                      border: `2px solid ${root}`,
                    }}
                  >
                    <h5 style={{ color: text }}>Decompression Type</h5>
                  </td>
                  <td
                    style={{
                      background: container,
                      color: text,
                      marginLeft: '10px',
                      border: `2px solid ${root}`,
                    }}
                  >
                    <select
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value='zip'>.zip</option>
                      <option value='gz'>.gz</option>
                      <option value='tar'>.tar</option>
                    </select>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>{' '}
        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          <Button color='primary' onClick={handleDefaultLayoutSubmit}>
            Submit
          </Button>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default PreProcessing
