import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Table, Button } from 'reactstrap'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { AuthContext } from '../../auth/AuthProvider'
import { useTheme } from '../../components/ThemeContext'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import axios from 'axios'

const CreateAppends = () => {
  const urlParams = useParams()
  const salesforceId = urlParams.salesforceId
  const { username, authToken } = useContext(AuthContext)
  const history = useNavigate()

  const [name, setName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [enteredEmail, setEnteredEmail] = useState('')
  const [emailList, setEmailList] = useState([])
  const [productNames, setProductNames] = useState([])
  const [changesSaved, setChangesSaved] = useState(false)
  const {
    background,
    text,
    container,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  }

  const fetchAccountForEdit = async () => {
    try {
      const url_endpoint =
        urlEndpoints.getAccountDetailsForAccountId(salesforceId)
      const response = await axios.get(url_endpoint, { headers })
      let identity_products = response.data.products.identity || []
      let attribute_products = response.data.products.attributes || []
      let combined_products = [...identity_products, ...attribute_products]
      setProductNames(combined_products)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchAccountForEdit()
  }, [])

  const handleEmailInputChange = (e) => {
    setEnteredEmail(e.target.value)
  }

  const handleListEmails = () => {
    setEmailList([...emailList, enteredEmail])
    setEnteredEmail('')
  }

  const handleDeleteEmail = (index) => {
    const updatedEmailList = [...emailList]
    updatedEmailList.splice(index, 1)
    setEmailList(updatedEmailList)
  }

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleSave = async () => {
    if (!name || !selectedOption) {
      alert('Please fill out all required fields.')
      return
    }
    if (name.includes('/')) {
      alert('Please do not include the "/" character in the name.')
      return
    }

    try {
      const formData = {
        name: name,
        product: selectedOption,
        username: username,
      }

      await axios.post(
        urlEndpoints.createNewAppendToAccount(salesforceId),
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )

      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  return (
    <div className='edit-table' style={{ background, color: text }}>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader>
          <div
            className='custom-card-head-style'
            style={{ background: tableheaderBackground, color: text }}
          >
            <h4>Create New Append Job</h4>
          </div>
        </CardHeader>
        <CardBody>
          <Table id='users' bordered dark>
            <tbody>
              <tr className='lables'>
                <th style={{ background: tableBackground, color: text }}>
                  Name
                </th>
                <th style={{ background: tableBackground, color: text }}>
                  <input
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      height: '45px',
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </th>
              </tr>
              <tr>
                <th style={{ background: tableBackground, color: text }}>
                  Product Selection
                </th>
                <th style={{ background: tableBackground, color: text }}>
                  <select
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    style={{
                      height: '45px',
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <option value=''>Select a Product</option>
                    {productNames.map((companyName) => (
                      <option key={companyName} value={companyName}>
                        {companyName}
                      </option>
                    ))}
                  </select>
                </th>
              </tr>
            </tbody>
          </Table>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              onClick={handleSave}
              color='primary'
              className='mr-2'
              style={{ marginRight: '10px' }}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              className='form-button'
              onClick={() => {
                history(-1)
              }}
            >
              Discard
            </Button>
          </div>
        </CardBody>
      </Card>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </div>
  )
}

export default CreateAppends
