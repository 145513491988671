import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Input,
} from 'reactstrap'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CSVReader from 'react-csv-reader'
import '../../css/AppendJobDetails.css'
import FieldMapping from './FieldMapping'
import { useTheme } from '../../components/ThemeContext'

const InputConfiguration = ({
  jobId,
  modal,
  toggle,
  product,
  configId,
  csvData,
  handleFile,
  productName,
}) => {
  const {
    background,
    text,
    root,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const [activeTab, setActiveTab] = useState('Default')
  const { authToken } = useContext(AuthContext)
  const token = authToken
  const [loading, setLoading] = useState(false)
  const [fileFormats, setFileFormats] = useState('')
  const [delimiters, setDelimiters] = useState('')
  const [fileFormatsDefault, setFileFormatsDefault] = useState('')
  const [delimitersDefault, setDelimitersDefault] = useState('')
  const [customLayoutHeaders, setCustomLayoutHeaders] = useState([])
  const [changesSaved, setChangesSaved] = useState(false)
  const [defaultEnable, setDefaultEnable] = useState(true)
  const [customDelimiter, setCustomDelimiter] = useState([])
  const [customFileFormat, setCustomFileFormat] = useState([])

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    fetch(urlEndpoints.getCustomLayoutDetails(jobId), {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        setFileFormats(data.info_attributes.file_format || 'CSV')
        setDelimiters(data.info_attributes.file_delimiter || 'COMMA')
        setCustomLayoutHeaders(data.info_attributes.mapped_headers || [])
      })
      .catch((error) =>
        console.error('Error fetching custom layout details:', error.message),
      )
  }, [configId, token])

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handleDropdownDelimiter = (e) => {
    setDelimiters(e.target.value)
  }
  const handleDropdownFileFormat = (e) => {
    setFileFormats(e.target.value)
  }

  const [customData, setCustomData] = useState({})

  let sendMappedFieldsToInputConfig = (response1) => {
    setCustomData(response1)
  }

  const default_fileout_delimiter = async (headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postDefaultLayout(jobId, productName), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`)
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => {
            setChangesSaved(false)
            toggle()
          }, 5000)
        })
        .catch((error) => {
          console.error('Error submitting default layout:', error.message)
        })
    })
  }
  const default_fileout_headers = async (secondRequestBody, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postCustomFileDetails(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(secondRequestBody),
      })
        .then((secondResponse) => {
          if (!secondResponse.ok) {
            throw new Error(
              `Second request failed with status: ${secondResponse.status}`,
            )
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => {
            setChangesSaved(false)
            toggle()
          }, 5000)
        })
        .catch((secondError) => {
          console.error('Error in second request:', secondError.message)
        })
    })
  }
  const handleDefaultLayoutSubmit = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    const response = await default_fileout_delimiter(headers)
    const secondRequestBody = {
      file_format: fileFormatsDefault,
      file_delimiter: delimitersDefault,
    }
    const response2 = await default_fileout_headers(secondRequestBody, headers)

    if (response && response2) {
      setLoading(false)
      window.location.reload(true)
    }
  }
  const custom_fileout_delimiter = async (requestBody, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postCustomLayout(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((error) => {
              throw new Error(
                error.Message ||
                  `Request failed with status: ${response.status}`,
              )
            })
          }

          resolve(true)
        })
        .catch((error) => {
          console.error('Error submitting custom layout:', error.message)
          window.alert(`Error submitting custom layout: ${error.message}`)
        })
    })
  }

  const custom_fileout_headers = async (secondRequestBody, headers) => {
    return new Promise((resolve, reject) => {
      fetch(urlEndpoints.postCustomFileDetails(jobId), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(secondRequestBody),
      })
        .then((secondResponse) => {
          if (!secondResponse.ok) {
            return secondResponse.json().then((error) => {
              throw new Error(
                error.Message ||
                  `Second request failed with status: ${secondResponse.status}`,
              )
            })
          }
          resolve(true)
          setChangesSaved(true)
          setTimeout(() => setChangesSaved(false), 5000)
          toggle()
        })
        .catch((secondError) => {
          console.error('Error in second request:', secondError.message)
          window.alert(`Error: ${secondError.message}`)
        })
    })
  }

  const handleCustomLayoutSubmit = async () => {
    setLoading(true)
    let requestBody = customData

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    const response = await custom_fileout_delimiter(requestBody, headers)
    const secondRequestBody = {
      file_format: fileFormats,
      file_delimiter: delimiters,
    }
    const response2 = await custom_fileout_headers(secondRequestBody, headers)
    if (response && response2) {
      setLoading(false)
      window.location.reload(true)
    }
  }

  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    fetch(urlEndpoints.defaultLayoutHeaders(productName), {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        setApiData(data)
        if (data.headers.length > 0 || csvData) {
          setDefaultEnable(false)
        }
        setFileFormatsDefault(data.file_format[0])
        setDelimitersDefault(data.file_delimiter[0])
      })
      .catch((error) => console.error('Error fetching data:', error.message))
  }, [])

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    fetch(urlEndpoints.getFileFormatDelimiter, {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        setCustomDelimiter(data.file_delimiter)
        setCustomFileFormat(data.file_format)
      })
      .catch((error) => console.error('Error fetching data:', error.message))
  }, [])

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          background: tableheaderBackground,
          color: text,
          maxWidth: '60%',
        }}
      >
        <ModalHeader
          toggle={toggle}
          style={{ background: tableheaderBackground, color: text }}
        >
          Input File Configuration
        </ModalHeader>
        <ModalBody style={{ background: root, color: text }}>
          <Nav style={{ background: root, color: text }} tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'Default' ? 'active' : ''}
                onClick={() => toggleTab('Default')}
              >
                Default Layout
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'Custom' ? 'active' : ''}
                onClick={() => toggleTab('Custom')}
              >
                Custom Layout
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            style={{ background: root, color: text }}
            activeTab={activeTab}
          >
            <TabPane style={{ background: root, color: text }} tabId='Default'>
              <br />
              <p>
                Files must match the specifications listed below. <br />
                Note that headers need to be in below specified order.
              </p>
              <div>
                <Table id='users' bordered dark>
                  <React.Fragment>
                    {apiData && (
                      <table>
                        <tbody>
                          {Object.entries(apiData).map(([field, value]) => (
                            <tr key={field}>
                              <th
                                style={{
                                  background: container,
                                  color: text,
                                  padding: '10px',
                                  border: `2px solid ${root}`,
                                  width: '50%',
                                  height: '80px',
                                }}
                              >
                                {field
                                  .replace(/_/g, ' ')
                                  .split(' ')
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1),
                                  )
                                  .join(' ')}
                              </th>
                              <th
                                style={{
                                  background: container,
                                  color: text,
                                  padding: '10px',
                                  border: `2px solid ${root}`,
                                  width: '200px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {Array.isArray(value)
                                  ? value.join(', ')
                                  : value}
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </React.Fragment>
                </Table>
                <div>
                  <label htmlFor='csv-reader' className='file-upload-label'>
                    <CloudUploadIcon className='file-upload-icon' />
                    <CSVReader
                      onFileLoaded={handleFile}
                      parserOptions={{
                        header: false,
                        skipEmptyLines: true,
                      }}
                      id='csv-reader'
                      className='csv-reader'
                    />
                  </label>
                  <br />
                  {csvData && (
                    <div style={{ overflowX: 'auto' }}>
                      <h4
                        style={{
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      >
                        CSV File Preview
                      </h4>
                      <table>
                        <thead>
                          <tr style={{ background: container, color: text }}>
                            {csvData[0].map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {csvData.slice(1).map((row, rowIndex) => (
                            <tr
                              style={{ background: container, color: text }}
                              key={rowIndex}
                            >
                              {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </TabPane>
            <TabPane style={{ background: root, color: text }} tabId='Custom'>
              <br />
              <p>
                Click below to configure a custom layout for the customer.{' '}
                <br />
                Note that the customer must follow the configured layout for all
                future uploads, unless updated by an admin.
              </p>
              <div>
                <Table id='users' bordered dark>
                  <>
                    <React.Fragment>
                      <tr>
                        <th
                          style={{
                            background: container,
                            color: text,
                            padding: '10px',
                            border: `2px solid ${root}`,
                            width: '50%',
                            height: '80px',
                          }}
                        >
                          File Format
                        </th>
                        <th
                          style={{
                            background: container,
                            color: text,
                            padding: '10px',
                            border: `2px solid ${root}`,
                            width: '50%',
                            height: '80px',
                          }}
                        >
                          <select
                            value={fileFormats}
                            onChange={handleDropdownFileFormat}
                            style={{
                              height: '45px',
                              width: '100%',
                              borderRadius: '8px',
                              backgroundColor: container,
                              color: text,
                              border: `1px solid ${root}`,
                            }}
                          >
                            {customFileFormat.map((fileformat, index) => (
                              <option key={index} value={fileformat}>
                                {fileformat}
                              </option>
                            ))}
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            background: container,
                            color: text,
                            padding: '10px',
                            border: `2px solid ${root}`,
                            width: '50%',
                            height: '80px',
                          }}
                        >
                          File Delimiter
                        </th>
                        <th
                          style={{
                            background: container,
                            color: text,
                            padding: '10px',
                            border: `2px solid ${root}`,
                            width: '50%',
                            height: '80px',
                          }}
                        >
                          <select
                            value={delimiters}
                            onChange={handleDropdownDelimiter}
                            style={{
                              height: '45px',
                              width: '100%',
                              borderRadius: '8px',
                              backgroundColor: container,
                              color: text,
                              border: `1px solid ${root}`,
                            }}
                          >
                            {customDelimiter.map((delimiter, index) => (
                              <option key={index} value={delimiter}>
                                {delimiter}
                              </option>
                            ))}
                          </select>
                        </th>
                      </tr>
                      {customLayoutHeaders.length > 0 && (
                        <tr>
                          <th
                            style={{
                              background: container,
                              color: text,
                              padding: '10px',
                              border: `2px solid ${root}`,
                              width: '50%',
                              height: '80px',
                            }}
                          >
                            Headers
                          </th>
                          <th
                            style={{
                              background: container,
                              color: text,
                              padding: '10px',
                              width: '50%',
                              border: `2px solid ${root}`,
                            }}
                          >
                            {customLayoutHeaders.join(', ')}
                          </th>
                        </tr>
                      )}
                    </React.Fragment>
                  </>
                </Table>
                <FieldMapping
                  product={product}
                  onDataReceived={sendMappedFieldsToInputConfig}
                  productName={productName}
                />
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          {activeTab === 'Default' && (
            <Button
              color='primary'
              onClick={handleDefaultLayoutSubmit}
              disabled={defaultEnable}
            >
              {loading ? 'Submitting...' : 'Submit for Default Layout'}
            </Button>
          )}

          {activeTab === 'Custom' && (
            <>
              {(!customData.headers || customData.headers.length === 0) && (
                <div style={{ color: 'red' }}>
                  *Please upload a custom file with the selected format and
                  delimiter, and map the necessary fields
                </div>
              )}
              <Button
                color='primary'
                onClick={handleCustomLayoutSubmit}
                disabled={
                  !customData.headers || customData.headers.length === 0
                }
              >
                {loading ? 'Submitting...' : 'Submit for Custom Layout'}
              </Button>
            </>
          )}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default InputConfiguration
