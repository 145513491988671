import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { Table } from 'reactstrap'
import '../../css/AccountDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'
const InstanceInfo = () => {
  const { background, text, tableBackground, tableheaderBackground } =
    useTheme()
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const instanceId = urlParams.instanceId
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState({})
  const url_point = urlEndpoints.getAccountDetailsForAccountId(accountId)
  const token = authToken

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  useEffect(() => {
    axios
      .get(url_point, { headers })
      .then((response) => {
        setData(response.data.activate_instances)
      })
      .catch((error) => {})
  }, [])

  return (
    <div className='users-container' style={{ background, color: text }}>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Instance Info</h4>{' '}
          </div>
        </CardHeader>
        <CardBody style={{ background: tableheaderBackground, color: text }}>
          <Table id='users' style={{ background, color: text }} bordered dark>
            <>
              {Object.values(data).map((item, index) =>
                item.id === instanceId ? (
                  <React.Fragment key={index}>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Name
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.name}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: tableBackground, color: text }}>
                        Created
                      </th>
                      <th style={{ background: tableBackground, color: text }}>
                        {item.created}
                      </th>
                    </tr>
                  </React.Fragment>
                ) : null,
              )}
            </>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default InstanceInfo
