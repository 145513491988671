import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import '../../css/ActiveInstances.css'
import { useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { Card } from 'reactstrap'
import { CardBody, CardHeader } from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'
function ActiveInstances(props) {
  const {
    background,
    text,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const accountCode = props.accountCode
  const companyName = props.companyName
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [errormessage, setErrorMessage] = useState(false)
  const token = authToken

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  useEffect(() => {
    axios
      .get(urlEndpoints.getAccountDetailsForAccountId(accountId), {
        headers,
      })
      .then((response) => {
        setData(response.data.activate_instances)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.Message)
        console.error('Error fetching data:', error)
      })
  }, [])

  if (!errormessage) {
    return (
      <div
        className='activeinstances-container'
        style={{ background, color: text, height: '100vh' }}
      >
        <Card style={{ background: tableheaderBackground, color: text }}>
          <CardHeader
            style={{ background: tableheaderBackground, color: text }}
          >
            <div
              className='custom-card-head-style'
              style={{ tableheaderBackground, color: text }}
            >
              {' '}
              <h4>Activate Instances</h4>
            </div>
          </CardHeader>
          <CardBody style={{ tableheaderBackground }}>
            <table id='activeinstances'>
              <tbody>
                {Object.values(data).map((item, index) => (
                  <tr key={index}>
                    <Link
                      className='activeinstances-link'
                      to={urlEndpoints.getInstanceInfoForAccount(
                        accountId,
                        item,
                      )}
                      state={{
                        companyName: companyName,
                        accountCode: accountCode,
                        activateInstanceName: item.name,
                      }}
                    >
                      <td style={{ background }}>
                        {item.name} ({item.config.clientName})
                      </td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    )
  } else {
    return (
      <div id='error-box'>
        <h2>{errormessage}</h2>
      </div>
    )
  }
}

export default ActiveInstances
