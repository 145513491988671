import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { Card, CardHeader, CardBody } from 'reactstrap'
import InstanceInfo from './InstanceInfo'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import '../../css/AccountDetails.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ConfigurationActiveInstances from './ConfigurationActiveInstances'
import { useTheme } from '../../components/ThemeContext'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
export default function DisplayActiveInstances() {
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const location = useLocation()
  const companyName = location.state.companyName
  const accountCode = location.state.accountCode
  const activateInstanceName = location.state.activateInstanceName

  const salesforceId = urlParams.salesforceId
  const instanceId = urlParams.instanceId
  const { background, text, tableBackground, tableheaderBackground } =
    useTheme()
  return (
    <div className='specific-account-details'>
      <Container>
        <Row>
          <Col>
            <Link
              to={urlEndpoints.getAccountInfoPage(
                accountCode,
                salesforceId,
                companyName,
              )}
              className='account-link'
            >
              {companyName}
            </Link>
            <ChevronRightIcon style={{ margin: '0 5px' }} />
            {activateInstanceName}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className='headers'>
            <InstanceInfo accountId={accountId}></InstanceInfo>
          </Col>
          <Col className='headers'>
            <ConfigurationActiveInstances
              accountId={accountId}
            ></ConfigurationActiveInstances>
          </Col>
          <Col className='headers'>
            <Card
              style={{ background: tableheaderBackground, color: text }}
              inverse
            >
              <CardHeader
                style={{ background: tableheaderBackground, color: text }}
              >
                <div
                  style={{ background: tableheaderBackground, color: text }}
                  className='custom-card-head-style'
                >
                  <h4>Integrations</h4>{' '}
                </div>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
