import React, { useState, useEffect, useContext } from 'react'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import '../../css/JobStatus.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { useTheme } from '../../components/ThemeContext'
const LogPopup = ({ logEntry, onClose }) => (
  <div className='log-popup'>
    <div className='log-popup-content'>
      <p>{logEntry}</p>
      <button onClick={onClose}>Close</button>
    </div>
  </div>
)

const JobStatus = () => {
  const { authToken } = useContext(AuthContext)
  const token = authToken
  const {
    background,
    text,
    tableBackground,
    tableText,
    tableheaderBackground,
    container,
    containerHeaader,
  } = useTheme()
  const urlParams = useParams()
  const jobId = urlParams.jobid
  const executionId = urlParams.executionid
  const salesforce_id = urlParams.salesforceid
  const [progress, setProgress] = useState(0)
  const [setHighlightedBoxes] = useState(0)
  const [jobExecutionStatus, setJobExecutionStatus] = useState({})
  const [setFailureBoxes] = useState([])
  const [jobDetails, setJobDetails] = useState(null)
  const [totalKeys, setTotalKeys] = useState(0)
  const [reportData, setReportData] = useState([])
  const [selectedLog, setSelectedLog] = useState(null)

  const truncateText = (text, maxLength) => {
    if (Array.isArray(text)) {
      const joinedText = text.join(', ')
      return truncateText(joinedText, maxLength)
    } else if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const handleLogClick = (logEntry) => {
    setSelectedLog(logEntry)
  }

  const closePopup = () => {
    setSelectedLog(null)
  }

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(urlEndpoints.getAllJobs, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const jobsData = response.data
        const filteredJob = jobsData.find(
          (job) => job.job_id === jobId && job.execution_id === executionId,
        )
        if (filteredJob) {
          setJobDetails(filteredJob)
          setTotalKeys(Object.keys(filteredJob || {}).length)
        } else {
          console.error('Job not found.')
        }
      } catch (error) {
        console.error('Error fetching job details:', error)
      }
    }

    fetchJobDetails()
  }, [jobId, token])

  useEffect(() => {
    const ReportLogs = async () => {
      try {
        const response = await axios.get(
          urlEndpoints.getReportLog(jobId, executionId),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const ReportData = response.data
        setReportData(ReportData)
      } catch (error) {
        console.error('Error fetching job details:', error)
      }
    }

    ReportLogs()
  }, [jobId, executionId, salesforce_id, token])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          urlEndpoints.getJobStatus(jobId, executionId),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        const data = await response.json()
        setJobExecutionStatus(data)

        const totalSteps = Object.keys(data || {}).length
        const successfulSteps = Object.values(data || {}).filter(
          (status) => status === 'Success',
        ).length

        setProgress((successfulSteps / totalSteps) * 100)
        setHighlightedBoxes(successfulSteps)
        const failureBoxKeys = Object.keys(data || {}).filter(
          (key) => data[key] === 'error',
        )
        setFailureBoxes(failureBoxKeys)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [jobId, executionId, totalKeys, token])

  return (
    <div className='job-page-container' style={{ background, color: text }}>
      <div
        className='diagram-box'
        style={{ background: tableheaderBackground, color: text }}
      >
        <div
          className='custom-progress-bar'
          style={{ background: tableheaderBackground, color: tableText }}
        >
          <div
            className='progress-bar'
            style={{ width: `${progress}%`, color: text }}
          >
            <span className='progress-text'>{Math.round(progress)}%</span>
          </div>
        </div>
        <br></br>
        <br></br>

        <h2>Diagram</h2>
        <div className='diagram-container'>
          {Object.entries(jobExecutionStatus || {}).map(
            ([boxName, status], index) => (
              <div
                key={index}
                className={`additional-box ${
                  status === 'Success'
                    ? 'success-box'
                    : status === 'error'
                      ? 'failure-box'
                      : status === 'Started'
                        ? 'started-box'
                        : 'pending-box'
                }`}
              >
                {boxName}
              </div>
            ),
          )}
        </div>
      </div>

      <div className='content-container' style={{ background, color: text }}>
        <div
          className='form-box'
          style={{
            background: tableheaderBackground,
            color: text,
          }}
        >
          <h2>Information Form</h2>
          <br />
          <table className='information-table'>
            <tbody>
              <tr>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Job ID
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.job_id || 'Loading...'}
                </td>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Account
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.account || 'Loading...'}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Input File
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.input_file_name || 'Loading...'}
                </td>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Status
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.execution_status || 'Loading...'}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Product
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.product || 'Loading...'}
                </td>
                <td
                  style={{
                    background: tableBackground,
                    color: text,
                    border: `2px solid ${tableheaderBackground}`,
                  }}
                >
                  Created At
                </td>
                <td
                  style={{
                    backgroundColor: tableheaderBackground,
                    color: text,
                  }}
                >
                  {jobDetails?.updated_date || 'Loading...'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />

        <div
          className='general-logs'
          style={{ background: tableheaderBackground, color: text }}
        >
          <h2>General Logs</h2>
          <div
            className='logs-table-container'
            style={{ background: tableheaderBackground, color: text }}
          >
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      background: tableBackground,
                      color: text,
                      border: `2px solid ${tableheaderBackground}`,
                    }}
                  >
                    Type
                  </th>
                  <th
                    style={{
                      background: tableBackground,
                      color: text,
                      border: `2px solid ${tableheaderBackground}`,
                    }}
                  >
                    Message
                  </th>
                  <th
                    style={{
                      background: tableBackground,
                      color: text,
                      border: `2px solid ${tableheaderBackground}`,
                    }}
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((execution, index) =>
                  execution.execution_log.map((logEntry, subIndex) => (
                    <tr key={index + '-' + subIndex}>
                      <td
                        style={{
                          background: tableBackground,
                          color: text,
                          border: `2px solid ${tableheaderBackground}`,
                        }}
                      >
                        {logEntry.type}
                      </td>
                      <td
                        onClick={() => handleLogClick(logEntry.log)}
                        style={{
                          cursor: 'pointer',
                          color: '#4fbeff',
                          background: tableBackground,
                          border: `2px solid ${tableheaderBackground}`,
                        }}
                      >
                        {truncateText(logEntry.log, 50)}
                      </td>
                      <td
                        style={{
                          background: tableBackground,
                          color: text,
                          border: `2px solid ${tableheaderBackground}`,
                        }}
                      >
                        {logEntry.date}
                      </td>
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          </div>

          {selectedLog && (
            <LogPopup logEntry={selectedLog} onClose={closePopup} />
          )}
        </div>
      </div>
    </div>
  )
}

export default JobStatus
