import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import '../../css/ActiveInstances.css'
import { useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { Card } from 'reactstrap'
import { CardBody, CardHeader } from 'reactstrap'
import AddIcon from '@mui/icons-material/Add'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'
function Append(props) {
  const {
    background,
    text,
    tableText,
    tableBackground,
    tableheaderBackground,
  } = useTheme()
  const urlParams = useParams()
  const salesforceId = urlParams.salesforceId
  const accountCode = props.accountCode
  const companyName = props.companyName
  const { username, authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const token = authToken

  const handleSwitchChange = (event, index, job_id) => {
    const newData = [...data]
    newData[index].status = event.target.checked
    setData(newData)
    const statusValue = event.target.checked
    axios
      .put(
        urlEndpoints.postJobStatus(job_id),
        {
          status: statusValue,
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {})
      .catch((error) => {
        console.error('Error updating switch status:', error)
      })
  }

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .get(urlEndpoints.getAppendList(accountCode), {
        headers,
      })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.Message)
        console.error('Error fetching data:', error)
      })
  }, [salesforceId, token])

  return (
    <div className='activeinstances-container'>
      {errorMessage ? (
        <div id='error-box'>
          <h2>{errorMessage}</h2>
        </div>
      ) : (
        <Card
          style={{ background: tableheaderBackground, color: text }}
          inverse
        >
          <CardHeader
            style={{ background: tableheaderBackground, color: text }}
          >
            <div className='custom-card-head-style'>
              <h4>Enrichment Jobs</h4>
              <Link to={urlEndpoints.navigateToAppends(accountCode)}>
                <AddIcon
                  style={{
                    height: '20px',
                    width: '20px',
                    verticalAlign: 'baseline',
                    background: tableheaderBackground,
                    color: text,
                  }}
                />
              </Link>
            </div>
          </CardHeader>
          <CardBody style={{ background: tableheaderBackground, color: text }}>
            <table id='users' style={{ background, color: tableText }}>
              <thead style={{ background, color: tableText }}>
                <tr>
                  <th style={{ background: tableBackground, color: tableText }}>
                    Name
                  </th>
                  <th style={{ background: tableBackground, color: tableText }}>
                    Last Run
                  </th>
                  <th style={{ background: tableBackground, color: tableText }}>
                    Enable/Disable
                  </th>
                </tr>
              </thead>
              <tbody style={{ background, color: text }}>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ background: tableBackground, color: text }}>
                        <Tooltip
                          title={item.append_name}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: tableheaderBackground,
                                color: text,
                                fontSize: '20px',
                                border: '2px solid #1a1d1f',
                                '& .MuiTooltip-arrow': {
                                  color: tableheaderBackground,
                                },
                              },
                            },
                          }}
                        >
                          <Link
                            className='activeinstances-link'
                            to={urlEndpoints.getAppendJobInfo(
                              accountCode,
                              salesforceId,
                              item.job_id,
                            )}
                            state={{
                              appendName: item.append_name,
                              companyName: companyName,
                            }}
                          >
                            {item.append_name.length > 15
                              ? `${item.append_name.slice(0, 15)}...`
                              : item.append_name}
                          </Link>
                        </Tooltip>
                      </td>
                      <td style={{ background: tableBackground, color: text }}>
                        {item.last_run ? item.last_run : 'N/A'}
                      </td>
                      <td style={{ background: tableBackground, color: text }}>
                        <Switch
                          checked={item.status}
                          onChange={(event) =>
                            handleSwitchChange(event, index, item.job_id)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan='3'
                      style={{ background: tableBackground, color: tableText }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default Append
