import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import FullyProtectedApp from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider, useTheme } from './components/ThemeContext'

const Root = () => {
  const { root, background, text } = useTheme()

  return (
    <div style={{ backgroundColor: root, color: text }}>
      <FullyProtectedApp />
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Root />
    </ThemeProvider>
  </React.StrictMode>,
)

reportWebVitals()
