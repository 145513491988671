import './App.css'
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import BaseLayout from './pages/BaseLayout'
import LoggedOut from './pages/LoggedOut'
import Billing from './components/Billing'
import AccountsList from './components/AccountsList'
import ProjectSetup from './components/ProjectSetup'
import Jobs from './components/Jobs'
import UsersSetup from './components/Users'
import Home from './components/Home'
import AccountInfo from './components/AccountInfo'
import { AuthContext, AuthProvider } from './auth/AuthProvider'
import EditUser from './components/sub_components/EditUserDetails'
import Login from './pages/Login'
import CreateNewAccount from './components/sub_components/CreateNewAccount'
import AddnewUser from './components/sub_components/AddNewUser'
import EditAccount from './components/sub_components/EditAccountDetails'
import CreateActiveInstance from './components/sub_components/CreateActiveInstance'
import CreateAppends from './components/sub_components/CreateNewAppendJob'
import Test from './components/Test'
import EditActiveInstances from './components/sub_components/EditActiveInstances'
import DisplayActiveInstances from './components/sub_components/DisplayActiveInstances'
import BillingDetails from './components/sub_components/BillingDetails'
import JobStatus from './components/sub_components/JobStatus'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { useContext } from 'react'
import { Security, LoginCallback } from '@okta/okta-react'
import { AuthChecker } from './auth/SecureRoute'
import AppendDetails from './components/sub_components/AppendDetails'
import EditAppendJobDetails from './components/sub_components/EditAppendJobDetails'
import {
  ThemeProvider,
  useTheme,
  ThemeContext,
} from './components/ThemeContext'

function App() {
  const { oktaAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <ThemeProvider style={{ backgroundColor: root }}>
      <div className='App'>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/oidc/callback' element={<AuthChecker />}>
              <Route path='' element={<BaseLayout />}>
                <Route path='/oidc/callback/' element={<Home />} />
                <Route path='/oidc/callback/billing' element={<Billing />} />
                <Route
                  path='/oidc/callback/billing/billingdetails'
                  element={<BillingDetails />}
                />
                <Route
                  path='/oidc/callback/accounts'
                  element={<AccountsList />}
                />
                <Route
                  path='/oidc/callback/accounts/new'
                  element={<CreateNewAccount />}
                />
                <Route
                  path='/oidc/callback/accounts/:accountId/:salesforceId'
                  element={<AccountInfo />}
                />
                <Route
                  path='/oidc/callback/accounts/:accountId/users/:email_id'
                  element={<EditUser />}
                />
                <Route
                  path='/oidc/callback/accounts/:accountId/edit'
                  element={<EditAccount />}
                />
                <Route
                  path='/oidc/callback/activeinstances/:accountId/instance/:instanceId'
                  element={<DisplayActiveInstances />}
                />
                <Route
                  path='/oidc/callback/activeinstances/:accountId'
                  element={<CreateActiveInstance />}
                />
                <Route
                  path='/oidc/callback/appends/:salesforceId'
                  element={<CreateAppends />}
                />
                <Route
                  path='/oidc/callback/accounts/:accountId/users/new'
                  element={<AddnewUser />}
                />
                <Route
                  path='/oidc/callback/editactiveinstances/:accountId/instance/:instanceId'
                  element={<EditActiveInstances />}
                />
                <Route
                  path='/oidc/callback/appendinstances/:accountCode/:accountName/instance/:jobid'
                  element={<AppendDetails />}
                />
                <Route
                  path='/oidc/callback/append/:accountId/:jobId/EditAppendJobDetails'
                  element={<EditAppendJobDetails />}
                />
                <Route path='/oidc/callback/jobs' element={<Jobs />} />
                <Route
                  path='/oidc/callback/jobs/:salesforceid/:jobid/:executionid/jobStatus'
                  element={<JobStatus />}
                />
                <Route
                  path='/oidc/callback/projects'
                  element={<ProjectSetup />}
                />
                <Route path='/oidc/callback/users' element={<UsersSetup />} />
              </Route>
            </Route>
            <Route path='/logout' element={<LoggedOut />} />
            <Route path='/test' element={<Test />} />
            <Route path='okta/callback' element={<LoginCallback />} />
          </Routes>
        </Security>
      </div>
    </ThemeProvider>
  )
}

const FullyProtectedApp = () => (
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
)
export default FullyProtectedApp
