import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { Link } from 'react-router-dom'
import InputConfiguration from './InputConfiguration'
import OutputConfiguration from './OutputConfiguration'
import InputSource from './InputSource'
import SelectFields from './SelectFields'
import PostProcessing from './PostProcessing'
import PreProcessing from './PreProcessing'
import OutSource from './OutSource'
import { useTheme } from '../../components/ThemeContext'

import { Card, CardHeader, CardBody, Table, Button } from 'reactstrap'
import EditIcon from '@mui/icons-material/Edit'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import '../../css/AppendJobDetails.css'

const AppendJobDetails = ({ accountId, jobId, accountCode }) => {
  const {
    background,
    text,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const [modal, setModal] = useState(false)
  const [csvData, setCsvData] = useState(null)
  const [fieldSelectionModal, setFieldSelectionModal] = useState(false)
  const [selectedFields, setSelectedFields] = useState([])
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [inputlayoutConfigS3, setInputlayoutConfigS3] = useState('')
  const [inputlayoutConfigSourceType, setInputlayoutConfigSourceType] =
    useState('')
  const [outputlayoutConfigSourceType, setOutputlayoutConfigSourceType] =
    useState('')

  const [inputlayoutConfigPrefix, setInputlayoutConfigPrefix] = useState('')
  const [inputlayoutConfigArn, setInputlayoutConfigArn] = useState('')
  const [productName, setProductName] = useState([])
  const [jobName, setJobName] = useState('')
  const [outputlayoutConfigS3, setOutputlayoutConfigS3] = useState('')
  const [outputlayoutConfigPrefix, setOutputlayoutConfigPrefix] = useState('')
  const [outputlayoutConfigArn, setOutputlayoutConfigArn] = useState('')
  const [bucketName, setBucketName] = useState('')
  const [inputSourceModal, setInputSourceModal] = useState(false)
  const toggleInputSourceModal = () => setInputSourceModal(!inputSourceModal)
  const [outputSourceModal, setOutputSourceModal] = useState(false)
  const toggleOutputSourceModal = () => setOutputSourceModal(!outputSourceModal)
  const toggleFieldSelectionModal = () =>
    setFieldSelectionModal(!fieldSelectionModal)
  const [downloading, setDownloading] = useState(false)
  const token = authToken

  const [outputFileModal, setOutputFileModal] = useState(false)
  const toggleOutputFileModal = () => setOutputFileModal(!outputFileModal)

  const [postProcessingModal, setPostProcessingModal] = useState(false)
  const togglePostProcessingModal = () =>
    setPostProcessingModal(!postProcessingModal)

  const [preProcessingModal, setPreProcessingModal] = useState(false)
  const togglePreProcessingModal = () =>
    setPreProcessingModal(!preProcessingModal)

  const toggle = () => {
    if (!modal) {
      setCsvData(null)
    }
    setModal(!modal)
  }

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .get(urlEndpoints.getAppendInstancesDetails(accountCode, jobId), {
        headers,
      })
      .then((response) => {
        setData(response.data)
        setProductName(response.data[0].product)
        setJobName(response.data[0].name)
        setBucketName(process.env.REACT_APP_S3_BUCKET_NAME)
        setInputlayoutConfigS3(response.data[0].input_info.s3 || '')
        setInputlayoutConfigSourceType(
          response.data[0].input_info.source_type || '',
        )
        setInputlayoutConfigPrefix(response.data[0].input_info.prefix || '')
        setInputlayoutConfigArn(response.data[0].input_info.arn || '')
        setOutputlayoutConfigS3(response.data[0].output_info.s3 || '')
        setOutputlayoutConfigSourceType(
          response.data[0].output_info.source_type || '',
        )
        setOutputlayoutConfigPrefix(response.data[0].output_info.prefix || '')
        setOutputlayoutConfigArn(response.data[0].output_info.arn || '')
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [accountId, jobId, token])

  const handleFile = (data, fileInfo) => {
    if (fileInfo.type === 'text/csv') {
      setCsvData(data.slice(0, 5))
    } else {
      alert('Please upload a valid CSV file.')
    }
  }

  const downloadCSV = async () => {
    try {
      setDownloading(true)
      const response = await axios.get(
        urlEndpoints.getSelectField(jobId, productName),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const fieldSelectionData = response.data

      if (fieldSelectionData.length === 0) {
        setDownloading(false)
        return
      }

      let csvContent = 'data:text/csv;charset=utf-8,'
      fieldSelectionData.forEach((value) => {
        csvContent += `${value}\n`
      })
      const csvData = encodeURI(csvContent)

      const link = document.createElement('a')
      link.setAttribute('href', csvData)
      link.setAttribute('download', 'selected_fields.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setDownloading(false)
    } catch (error) {
      console.error('Error fetching or downloading data:', error)
      setDownloading(false)
    }
  }

  return (
    <div>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Job Configurations</h4>{' '}
            <Link
              to={`/oidc/callback/append/${accountCode}/${jobId}/EditAppendJobDetails`}
            >
              <EditIcon
                style={{
                  color: text,
                  height: '20px',
                  width: '20px',
                  verticalAlign: 'baseline',
                }}
              />
            </Link>
          </div>
        </CardHeader>
        <CardBody
          style={{ background: tableheaderBackground, color: tableText }}
        >
          <Table id='users'>
            {data.map((item, index) => (
              <tbody>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Name
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    {item.name}
                  </th>
                </tr>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Product Selection
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    {item.selectedfields}
                  </th>
                </tr>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Input File Configuration
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    <Button style={{ margin: '10px' }} onClick={toggle}>
                      File Layout
                    </Button>
                    <InputConfiguration
                      jobId={jobId}
                      modal={modal}
                      toggle={toggle}
                      product={item.product}
                      configId={item.product === 'identity' ? 1 : 2}
                      csvData={csvData}
                      handleFile={handleFile}
                      productName={item.product}
                    />

                    <Button
                      style={{ margin: '10px' }}
                      onClick={toggleInputSourceModal}
                    >
                      Input Source
                    </Button>
                    <InputSource
                      modal={inputSourceModal}
                      toggle={toggleInputSourceModal}
                      jobId={jobId}
                      arn={inputlayoutConfigArn}
                      prefix={inputlayoutConfigPrefix}
                      s3={inputlayoutConfigS3}
                      source_type={inputlayoutConfigSourceType}
                      bucket_name={bucketName}
                      account_code={accountCode}
                      job_name={jobName}
                    />
                    <Button
                      style={{ margin: '10px' }}
                      onClick={togglePreProcessingModal}
                    >
                      Pre Processing
                    </Button>
                    <PreProcessing
                      modal={preProcessingModal}
                      toggle={togglePreProcessingModal}
                      jobId={jobId}
                    />
                  </th>
                </tr>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Field Selection
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    <Button
                      style={{ margin: '10px' }}
                      onClick={toggleFieldSelectionModal}
                    >
                      Select Field
                    </Button>
                    <SelectFields
                      jobId={jobId}
                      modal={fieldSelectionModal}
                      toggle={toggleFieldSelectionModal}
                      selected={selectedFields}
                      setSelected={setSelectedFields}
                      product={item.selectedfields}
                      productName={item.product}
                    />
                    <Button style={{ margin: '10px' }} onClick={downloadCSV}>
                      {downloading ? 'Downloading...' : 'Download'}
                    </Button>
                  </th>
                </tr>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Output File Configuration
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    <Button
                      style={{ margin: '10px' }}
                      onClick={toggleOutputFileModal}
                    >
                      File Layout
                    </Button>
                    <OutputConfiguration
                      modal={outputFileModal}
                      toggle={toggleOutputFileModal}
                      jobId={jobId}
                    />
                    <Button
                      style={{ margin: '10px' }}
                      onClick={toggleOutputSourceModal}
                    >
                      Output Source
                    </Button>
                    <OutSource
                      modal={outputSourceModal}
                      toggle={toggleOutputSourceModal}
                      jobId={jobId}
                      arn={outputlayoutConfigArn}
                      prefix={outputlayoutConfigPrefix}
                      s3={outputlayoutConfigS3}
                      source_type={outputlayoutConfigSourceType}
                      bucket_name={bucketName}
                      account_code={accountCode}
                      job_name={jobName}
                    />
                    <Button
                      style={{ margin: '10px' }}
                      onClick={togglePostProcessingModal}
                    >
                      Post Processing
                    </Button>
                    <PostProcessing
                      modal={postProcessingModal}
                      toggle={togglePostProcessingModal}
                      jobId={jobId}
                    />
                  </th>
                </tr>
                <tr>
                  <th style={{ background: tableBackground, color: text }}>
                    Notifications
                  </th>
                  <th style={{ background: tableBackground, color: text }}>
                    {' '}
                    {item.notifications.length > 0 ? (
                      item.notifications.join(', ')
                    ) : (
                      <p>*Click on edit to add notification</p>
                    )}
                  </th>
                </tr>
              </tbody>
            ))}
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default AppendJobDetails
