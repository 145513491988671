import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CSVReader from 'react-csv-reader'
import '../../css/FieldMapping.css'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useContext } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { useTheme } from '../../components/ThemeContext'
const FieldMapping = ({ product, onDataReceived, productName }) => {
  const {
    background,
    text,
    root,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const [csvData, setCsvData] = useState(null)
  const { authToken } = useContext(AuthContext)

  let initialStaticColumns = [
    { id: 0, name: 'fname', csvColumn: '' },
    { id: 1, name: 'lname', csvColumn: '' },
    { id: 2, name: 'address', csvColumn: '' },
    { id: 3, name: 'city', csvColumn: '' },
    { id: 4, name: 'state', csvColumn: '' },
    { id: 5, name: 'zip', csvColumn: '' },
    { id: 6, name: 'phone', csvColumn: '' },
    { id: 7, name: 'email', csvColumn: '' },
  ]

  const [staticColumns, setStaticColumns] = useState(initialStaticColumns)

  const [showPreviewBeforeMapping, setShowPreviewBeforeMapping] =
    useState(false)
  const [showPreviewAfterMapping, setShowPreviewAfterMapping] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleFileCustom = (data, fileInfo) => {
    setCsvData(data.slice(0, 3))
    setShowPreviewBeforeMapping(true)
  }

  const handleStaticColumnChange = (index, selectedCsvColumn) => {
    setStaticColumns((prevColumns) =>
      prevColumns.map((column, i) =>
        i === index ? { ...column, csvColumn: selectedCsvColumn } : column,
      ),
    )
  }
  let [dataToSend] = useState({})
  const handleMappingPreviewButtonClick = () => {
    let selectedMappedFields = {}

    staticColumns.forEach((column) => {
      if (column.csvColumn) {
        selectedMappedFields[column.name] = column.csvColumn
      }
    })
    const csvHeaders = csvData ? csvData[0] : []
    dataToSend = {
      ...selectedMappedFields,
      headers: csvHeaders,
    }
    const requestBody = {
      name: getColumnCsvColumn('name'),
      fname: getColumnCsvColumn('fname'),
      lname: getColumnCsvColumn('lname'),
      address: getColumnCsvColumn('address'),
      zip: getColumnCsvColumn('zip'),
      city: getColumnCsvColumn('city'),
      state: getColumnCsvColumn('state'),
      phone: getColumnCsvColumn('phone'),
      email: getColumnCsvColumn('email'),
      product: product,
    }

    fetch(urlEndpoints.fieldvalidation, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        const combinationOptions = Object.entries(data.combinations).map(
          ([key, value]) => {
            return `${key}: ${value.join(', ')}`
          },
        )
        if (data.flag === true) {
          handleSendData()
          setShowPreviewAfterMapping(true)
          setIsModalOpen(false)
        } else {
          alert(
            `Please select one of the combinations:\n\n${combinationOptions.join(
              '\n',
            )}`,
          )
        }
      })
      .catch((error) => {
        console.error('Error occurred during validation:', error)
        alert('An error occurred during validation. Please try again later.')
      })
  }

  const getColumnCsvColumn = (columnName) => {
    const column = staticColumns.find((column) => column.name === columnName)
    return column ? column.csvColumn || 'None' : 'None'
  }
  const handleSendData = () => {
    onDataReceived(dataToSend)
  }

  return (
    <div className='field-mapping-container'>
      <label htmlFor='csv-reader' className='file-upload-label'>
        <CloudUploadIcon className='file-upload-icon' />
        <CSVReader
          onFileLoaded={handleFileCustom}
          parserOptions={{ header: false, skipEmptyLines: true }}
        />
      </label>
      <hr></hr>

      {csvData && (
        <div className='center-button'>
          <button className='map-button' onClick={() => setIsModalOpen(true)}>
            Map
          </button>
        </div>
      )}
      {showPreviewBeforeMapping && (
        <div>
          <br />
          <h4 style={{ fontSize: '20px', textAlign: 'center' }}>
            Data Preview Before Mapping
          </h4>
          <div className='preview-table'>
            <table>
              <thead>
                <tr>
                  {csvData[0].map((header, index) => (
                    <th
                      style={{ background: container, color: text }}
                      key={index}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td
                        style={{ background: container, color: text }}
                        key={cellIndex}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        style={{ background: tableheaderBackground, color: text }}
        toggle={() => setIsModalOpen(false)}
      >
        <ModalHeader style={{ background: tableheaderBackground, color: text }}>
          Map Columns
        </ModalHeader>
        <ModalBody style={{ background: root, color: text }}>
          <table className='mapping-table'>
            <thead>
              <tr>
                <th style={{ background: root, color: text }}>Static Column</th>
                <th style={{ background: root, color: text }}>Select Column</th>
              </tr>
            </thead>
            <tbody>
              {staticColumns.map((column, index) => (
                <tr key={column.id}>
                  <td style={{ background: root, color: text }}>
                    {column.name}
                  </td>
                  <td style={{ background: root, color: text }}>
                    <select
                      value={column.csvColumn}
                      onChange={(e) =>
                        handleStaticColumnChange(index, e.target.value)
                      }
                      style={{
                        height: '45px',
                        width: '100%',
                        borderRadius: '8px',
                        backgroundColor: container,
                        color: text,
                        border: `1px solid ${root}`,
                      }}
                    >
                      <option value=''>None</option>
                      {csvData && csvData[0]
                        ? csvData[0].map((csvColumn, columnIndex) => (
                            <option key={columnIndex} value={csvColumn}>
                              {csvColumn}
                            </option>
                          ))
                        : null}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>

        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          <button onClick={handleMappingPreviewButtonClick}>Validate</button>
        </ModalFooter>
      </Modal>

      {showPreviewAfterMapping && (
        <div>
          <br />
          <h4 style={{ fontSize: '20px', textAlign: 'center' }}>
            Data Preview After Mapping
          </h4>
          <div className='preview-table'>
            <table>
              <thead>
                <tr style={{ background: container, color: text }}>
                  {staticColumns.map((column) => (
                    <th key={column.id}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.slice(1).map((row, rowIndex) => (
                  <tr
                    style={{ background: container, color: text }}
                    key={rowIndex}
                  >
                    {staticColumns.map((column) => (
                      <td key={column.id}>
                        {row[csvData[0].indexOf(column.csvColumn)]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default FieldMapping
