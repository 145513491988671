import { Container } from 'reactstrap'
import React, { useContext } from 'react'
import '../css/Home.css'
import { AuthContext } from '../auth/AuthProvider'
import { useTheme } from './ThemeContext'

function Home() {
  const { firstname } = useContext(AuthContext)
  const { bg, text } = useTheme()

  return (
    <div style={{ background: bg, color: text }}>
      <div className='home-component'>
        <Container>
          <h1>
            Hello {firstname}, Welcome to the First Party Enrichment admin tool.
          </h1>
          <br></br>
          <h2>
            To start configuring or editing customer accounts, go to the
            accounts section on the left.
          </h2>
        </Container>
      </div>
    </div>
  )
}

export default Home
