import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'
import axios from 'axios'
import { useTheme } from '../../components/ThemeContext'
import '../../css/CreateNewAccount.css'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'

const EditAccountDetails = () => {
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const history = useNavigate()
  const { authToken } = useContext(AuthContext)
  const {
    background,
    text,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const [enableActivate, setEnableActivate] = useState(false)
  const [enableGuardian, setEnableGuardian] = useState(false)
  const [accountCode, setAccountCode] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [setTrayioId] = useState('')
  const [error, setError] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [formChanged, setFormChanged] = useState(false)
  const [changesSaved, setChangesSaved] = useState(false)
  const [identityCheckboxes, setIdentityCheckboxes] = useState([])
  const [attributeCheckboxes, setAttributeCheckboxes] = useState([])

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  }

  const fetchProducts = async () => {
    try {
      const response = await axios.get(urlEndpoints.getProducts, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })

      const products = response.data.product_name

      const identityCheckboxes = products.identity
        ? products.identity.map((item) => ({
            name: item,
            checked: false,
          }))
        : []

      const attributeCheckboxes = products.attributes
        ? products.attributes.map((item) => ({
            name: item,
            checked: false,
          }))
        : []

      setIdentityCheckboxes(identityCheckboxes)
      setAttributeCheckboxes(attributeCheckboxes)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [authToken])

  const fetchAccountForEdit = async () => {
    try {
      const url_endpoint = urlEndpoints.getAccountDetailsForAccountId(accountId)
      const response = await axios.get(url_endpoint, { headers })
      setCompanyName(response.data.company)

      const isIdentity = Object.keys(response.data.products).includes(
        'identity',
      )
      if (isIdentity) {
        setEnableActivate(true)
        setIdentityCheckboxes((prev) =>
          prev.map((checkbox) => ({
            ...checkbox,
            checked: response.data.products.identity.includes(checkbox.name),
          })),
        )
      } else {
        setEnableActivate(false)
      }

      const isAttribute = Object.keys(response.data.products).includes(
        'attributes',
      )
      if (isAttribute) {
        setEnableGuardian(true)
        setAttributeCheckboxes((prev) =>
          prev.map((checkbox) => ({
            ...checkbox,
            checked: response.data.products.attributes.includes(checkbox.name),
          })),
        )
      } else {
        setEnableGuardian(false)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchAccountForEdit()
  }, [])

  const handleFormSubmit = async () => {
    let products = {}

    if (enableActivate) {
      const identity_selectedValues = identityCheckboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.name)

      if (identity_selectedValues.length > 0) {
        products['identity'] = identity_selectedValues
      } else {
        alert('Please select at least one subproduct for Identity.')
        return
      }
    }

    if (enableGuardian) {
      const attributes_selectedValues = attributeCheckboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.name)

      if (attributes_selectedValues.length > 0) {
        products['attributes'] = attributes_selectedValues
      } else {
        alert('Please select at least one subproduct for Attributes.')
        return
      }
    }

    if (!enableActivate && !enableGuardian) {
      alert('Please select at least one product (Identity or Attributes).')
      return
    }

    const formData = {
      company: companyName,
      products: products,
    }

    try {
      await axios.put(
        urlEndpoints.getAccountDetailsForAccountId(accountId),
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  const handleAttributeCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    setAttributeCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
    setFormChanged(true)
  }

  const handleIdentityCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    setIdentityCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
    setFormChanged(true)
  }

  const isValidAccountCode = (code) => {
    const pattern = new RegExp(
      '^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$',
    )
    return pattern.test(code)
  }

  const handleInputChange = (event) => {
    const value = event.target.value
    if (!isValidAccountCode(value)) {
      setError('Invalid account code format')
    } else {
      setError('')
    }
    setAccountCode(value)
  }

  const handleCompanyNameChange = (event) => {
    const value = event.target.value
    setCompanyName(value)
    if (value.trim() === '') {
      setCompanyNameError('Company name cannot be empty')
    } else {
      setCompanyNameError('')
    }
    setFormChanged(true)
  }

  return (
    <div className='new-account-page' style={{ background, color: text }}>
      <Card
        color='dark'
        style={{ background: tableheaderBackground, color: text }}
        inverse
      >
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div>
            <h4 style={{ textAlign: 'center' }}>Edit Account</h4>
          </div>
        </CardHeader>

        <CardBody style={{ background: tableBackground, color: text }}>
          <Label for='company-inp' sm={2}>
            <h4>Company</h4>
          </Label>

          <Col sm={8}>
            <Input
              id='company-inp'
              name='company'
              placeholder='Enter Company Code'
              type='text'
              value={companyName}
              onChange={handleCompanyNameChange}
            />

            {companyNameError && (
              <p className='text-danger'>{companyNameError}</p>
            )}
          </Col>

          <Col>
            <Label for='products-inp' sm={2}>
              <h4>Products</h4>
            </Label>
          </Col>

          <Col>
            <Input
              id='identity-checkbox2'
              type='checkbox'
              onChange={() => {
                setEnableActivate(!enableActivate)
              }}
              checked={enableActivate}
            />
            <Label check>Identity</Label>
          </Col>

          <Row>
            {enableActivate && (
              <div className='child-checkboxes'>
                {identityCheckboxes.map((checkbox, index) => (
                  <div key={index} className='child-checkbox'>
                    <input
                      type='checkbox'
                      className='spaced-label'
                      checked={checkbox.checked}
                      onChange={handleIdentityCheckboxChange}
                      name={checkbox.name}
                    />
                    <label>{checkbox.name}</label>
                  </div>
                ))}
              </div>
            )}

            <Col>
              <Input
                id='attributes-checkbox2'
                type='checkbox'
                onChange={() => {
                  setEnableGuardian(!enableGuardian)
                }}
                checked={enableGuardian}
              />
              <Label check>Attributes</Label>
            </Col>
            {enableGuardian && (
              <div className='child-checkboxes'>
                {attributeCheckboxes.map((checkbox, index) => (
                  <div key={index} className='child-checkbox'>
                    <input
                      type='checkbox'
                      className='spaced-label'
                      checked={checkbox.checked}
                      onChange={handleAttributeCheckboxChange}
                      name={checkbox.name}
                    />

                    <label>{checkbox.name}</label>
                  </div>
                ))}
              </div>
            )}
          </Row>

          <div className='buttons-section'>
            <ButtonGroup>
              <Button
                color='primary'
                className='save-button'
                onClick={handleFormSubmit}
                disabled={
                  !formChanged ||
                  (!enableActivate && !enableGuardian) ||
                  (enableActivate &&
                    identityCheckboxes.every((checkbox) => !checkbox.checked) &&
                    enableGuardian &&
                    attributeCheckboxes.every((checkbox) => !checkbox.checked))
                }
              >
                Save
              </Button>
              <Button className='discard-button' onClick={() => history(-1)}>
                Discard
              </Button>
            </ButtonGroup>
          </div>
        </CardBody>
      </Card>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </div>
  )
}

export default EditAccountDetails
