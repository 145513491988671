import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
import { AuthContext } from '../auth/AuthProvider'
import { useTheme } from './ThemeContext'
function UsersSetup() {
  const { authToken } = useContext(AuthContext)
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const {
    background,
    text,
    containerHeader,
    tableBackground,
    tableText,
    tableheaderBackground,
    container,
  } = useTheme()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(urlEndpoints.getallUsers, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        setUserData(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error:', error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [authToken])

  const columns = [
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Email', selector: 'email', sortable: true },
    {
      name: 'Signed Up',
      selector: 'signed_up',
      sortable: true,
      cell: (row) => (row.signed_up === 'true' ? 'True' : 'False'),
    },
    {
      name: 'Active Status',
      selector: 'active',
      sortable: true,
      cell: (row) => (row.active ? 'Active' : 'Inactive'),
    },
  ]

  const filteredData = userData.filter((user) =>
    Object.values(user).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div style={{ background, color: text }}>
      <h2>SFTP Users List</h2>
      <input
        className='search-input-box'
        type='search'
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <DataTable
        className='data-wrapper'
        columns={columns}
        data={filteredData}
        direction='auto'
        fixedHeaderScrollHeight='300px'
        pagination
        responsive
        subHeaderAlign='left'
        subHeaderWrap
        customStyles={{
          headRow: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          headCells: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          rows: {
            style: {
              background: container,
              color: tableText,
            },
          },
          cells: {
            style: {
              background: container,
              color: tableText,
            },
          },
          pagination: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
        }}
      />
    </div>
  )
}

export default UsersSetup
