import React, { useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
} from 'reactstrap'
import { useTheme } from '../../components/ThemeContext'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import axios from 'axios'

const AddNewUser = () => {
  const {
    background,
    text,
    container,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const { authToken } = useContext(AuthContext)
  const history = useNavigate()

  const [changesSaved, setChangesSaved] = useState(false)
  const [newUserName, setNewUserName] = useState('')
  const [newUserEmail, setNewUserEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const handleNameChange = (e) => {
    setNewUserName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setNewUserEmail(e.target.value)
  }

  const addUserToAccount = async () => {
    if (newUserEmail.length < 1 || newUserName.length < 1) {
      alert('Name and Email Field should not be empty!!')
      return
    }

    if (!isValidEmail(newUserEmail)) {
      alert('Please enter a valid email address!!')
      return
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken,
    }
    const data = {
      email: newUserEmail,
      name: newUserName,
      active: true,
      last_login: 0,
    }
    try {
      await axios.post(urlEndpoints.addNewUserToAccountId(accountId), data, {
        headers,
      })
      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      alert(error.response.data.Message)
      console.error('error', error.response.data.Message)
    }
  }

  return (
    <div className='edit-table' style={{ background, color: text }}>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader>
          <div
            className='custom-card-style'
            style={{ background: tableheaderBackground, color: text }}
          >
            <h3>Add New User</h3>
          </div>
        </CardHeader>
        <CardBody>
          <Table id='users' bordered dark>
            <tbody>
              <tr>
                <th style={{ background: tableBackground, color: text }}>
                  Name
                </th>
                <td style={{ background: tableBackground, color: text }}>
                  <Input
                    type='text'
                    value={newUserName}
                    onChange={handleNameChange}
                    placeholder='Enter name'
                  />
                </td>
              </tr>
              <tr>
                <th style={{ background: tableBackground, color: text }}>
                  Email
                </th>
                <td style={{ background: tableBackground, color: text }}>
                  <Input
                    type='email'
                    value={newUserEmail}
                    onChange={handleEmailChange}
                    placeholder='Enter email'
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <div className='d-flex justify-content-end mt-3'>
            <Button
              onClick={addUserToAccount}
              color='primary'
              className='mr-2'
              style={{ marginRight: '10px' }}
            >
              Save
            </Button>
            <Button
              variant='outlined'
              className='form-button'
              onClick={() => {
                history(-1)
              }}
            >
              Discard
            </Button>
          </div>
        </CardBody>
      </Card>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </div>
  )
}

export default AddNewUser
