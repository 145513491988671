import React, { createContext, useState, useEffect } from 'react'
export const ThemeContext = createContext()
export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const storedTheme = localStorage.getItem('theme')
    return storedTheme ? JSON.parse(storedTheme) : true
  })

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(darkMode))
  }, [darkMode])

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode)
  }

  const theme = {
    darkMode,
    toggleDarkMode,
    background: darkMode ? '#2a3642' : '#D9D9D8',
    bg: darkMode ? '#2a3642' : '#D9D9D8',
    text: darkMode ? '#ffffff' : '#000000',
    root: darkMode ? '#2a3642' : '#D9D9D8',
    selectfieldbg: darkMode ? '#404040' : '#D9D9D8',
    tableText: darkMode ? '#ffffff' : '#000000',
    tableBackground: darkMode ? '#2a3642' : '#F8FAF0',
    tableheaderBackground: darkMode ? '#1a1d1f' : '#808080',
    container: darkMode ? '#1a1d1f' : '#F8FAF0',
    containerHeader: darkMode ? '#404040' : '#808080',
  }

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  return React.useContext(ThemeContext)
}
