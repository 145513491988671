import React, { createContext, useState, useEffect } from 'react'
import { OktaAuth } from '@okta/okta-auth-js'
import { urlEndpoints } from '../endpoints/ApiEndpoints'

export const AuthContext = createContext({
  isLoggedIn: false,
  isAdmin: false,
  username: null,
  authToken: null,
  useremail: null,
  firstname: null,
  oktaAuth: null,
  getUserInfo: () => {},
})

const AuthProvider = ({ children }) => {
  const [isLoggedIn] = useState(false)
  const [isAdmin] = useState(false)
  const [username, setUsername] = useState('')
  const [firstname, setFirstname] = useState('')
  const [useremail, setUserEmail] = useState('')
  const [authToken, setAuthToken] = useState('')

  const oktaAuth = new OktaAuth({
    issuer: urlEndpoints.oktaIssuerURL,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/okta/callback',
    pkce: true,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    tokenManager: {
      storage: 'sessionStorage',
      autoRenew: true,
    },
  })

  const getOktaToken = () => {
    const oktaToken = oktaAuth.getAccessToken()
    setAuthToken(oktaToken)
  }

  const getUserInfo = async () => {
    const result = await oktaAuth.getUser()
    setUsername(result.given_name + ' ' + result.family_name)
    setFirstname(result.given_name)
    setUserEmail(result.email)
  }

  useEffect(() => {
    getOktaToken()
  }, [isLoggedIn, isAdmin, username, authToken, oktaAuth])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isAdmin,
        username,
        useremail,
        firstname,
        authToken,
        oktaAuth,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export { AuthProvider }
