import React from 'react'
import '.././css/LoggedOut.css'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import lock from '../static_assets/locked.json'
export default function LoggedOut() {
  const [redirectToSignIn] = useState(false)

  return (
    <div className='LoggedOut'>
      <Player
        src={lock}
        className='lockplayer'
        loop
        autoplay
        style={{ height: '400px', width: '400px' }}
      />
      <h1>You Are LoggedOut</h1>
      {redirectToSignIn && <Navigate to='/login' />}
    </div>
  )
}
