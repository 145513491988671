import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DualListBox from 'react-dual-listbox'
import { Alert } from '@mui/material'
import { useTheme } from '../../components/ThemeContext'
import '../../css/AppendJobDetails.css'

const SelectFields = ({
  jobId,
  modal,
  toggle,
  selected,
  setSelected,
  product,
  productName,
}) => {
  const { authToken } = useContext(AuthContext)
  const [options, setOptions] = useState([])
  const { darkMode } = useTheme()
  const dualListBoxClassName = darkMode
    ? 'dark-mode-dual-listbox'
    : 'light-mode-dual-listbox'

  const [selectedOptions, setSelectedOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [popupContent, setPopupContent] = useState('')
  const [changesSaved, setChangesSaved] = useState(false)
  const {
    background,
    selectfieldbg,
    text,
    root,
    tableheaderBackground,
    container,
    containerHeader,
  } = useTheme()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const allFieldsResponse = await axios.get(
          urlEndpoints.getAllFields(productName),
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        const allFields = allFieldsResponse.data

        const selectedFieldsResponse = await axios.get(
          urlEndpoints.getSelectField(jobId, productName),
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        const selectedFields = selectedFieldsResponse.data
        const selectedValues = selectedFields.map((field) => field.value)

        setSelectedOptions(selectedFieldsResponse.data)
        const remainingFields = allFields.map(({ label, options }) => ({
          label,
          options: options.filter(
            ({ value }) => !selectedValues.includes(value),
          ),
        }))
        setOptions(remainingFields)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [jobId, authToken])

  useEffect(() => {
    if (changesSaved) {
      const timer = setTimeout(() => {
        setChangesSaved(false)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [changesSaved])

  const handleSubmit = async () => {
    if (
      (product === 'First Party Enrichment: 11 -50 Attributes' &&
        (selectedOptions.length > 50 || selectedOptions.length < 11)) ||
      (product === 'First Party Enrichment: up to 10 Attributes' &&
        selectedOptions.length > 10) ||
      (product === 'First Party Enrichment: 50+ Attributes' &&
        selectedOptions.length < 50)
    ) {
      setPopupOpen(true)
      setPopupContent(
        product === 'First Party Enrichment: 11 -50 Attributes'
          ? 'The current selection exceeds the allowed limit which is 11-50.'
          : product === 'First Party Enrichment: up to 10 Attributes'
            ? 'The current selection exceeds the allowed limit which is 10.'
            : 'The current selection is less than the required 50 attributes for this product.',
      )
    } else {
      setPopupOpen(false)
      setPopupContent('')
    }
    try {
      setLoading(true)
      const response = await axios.post(
        urlEndpoints.postSelectFields(jobId),
        {
          selected_fields: selectedOptions,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )

      if (response.status === 200) {
        setChangesSaved(true)
        toggle()
      } else {
        throw new Error(`Request failed with status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error updating selected fields:', error)
      alert(error.response.data.Message)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (newValue) => {
    setSelected(newValue)
    setSelectedOptions(newValue)
  }

  return (
    <>
      <Modal
        isOpen={popupOpen}
        toggle={() => setPopupOpen(false)}
        size='sm'
        centered
        backdrop='static'
      >
        <ModalBody style={{ background: root, color: text }}>
          <Alert
            style={{
              backgroundColor: container,
              color: text,
            }}
            severity='warning'
          >
            {popupContent}
          </Alert>
          <div style={{ textAlign: 'right' }}>
            <Button
              style={{ maxWidth: '100px' }}
              color='secondary'
              onClick={() => setPopupOpen(false)}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size='lg'
        scrollable={true}
        backdrop='static'
        style={{
          maxWidth: '60%',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <ModalHeader
          style={{ background: tableheaderBackground, color: text }}
          toggle={toggle}
        >
          Field Selection
        </ModalHeader>
        <ModalBody
          style={{ background: selectfieldbg, color: text }}
          className='dark-mode-modal-body'
        >
          Using the list on the left, select what fields will be provided in the
          output file
          <hr />
          <DualListBox
            canFilter
            showOrderButtons
            showHeaderLabels
            options={options}
            selected={selectedOptions}
            onChange={handleChange}
            className={dualListBoxClassName}
          />
        </ModalBody>
        <div
          style={{ background: selectfieldbg, color: text }}
          className='count'
        >
          Selected Fields: {selectedOptions.length}
        </div>
        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          <Button color='primary' onClick={handleSubmit} disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default SelectFields
