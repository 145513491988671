import React, { useContext, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { AuthContext } from '../../auth/AuthProvider'
import { useLocation } from 'react-router-dom'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import axios from 'axios'
import { useTheme } from '../../components/ThemeContext'
import styled from 'styled-components'
import '../../css/Billing.css'

const CellText = styled.div`
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

function BillingDetails() {
  const {
    background,
    containerHeader,
    tableText,
    text,
    container,
    tableBackground,
    tableheaderBackground,
  } = useTheme()

  const { authToken } = useContext(AuthContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [billingData, setBillingData] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)

  const location = useLocation()
  const { startDate, endDate, company } = location.state || {}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${urlEndpoints.getBillingDetails}?start_date=${startDate}&end_date=${endDate}&company_name=${company}`
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
        setBillingData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (authToken && startDate && endDate && company) {
      fetchData()
    }
  }, [authToken, startDate, endDate, company])

  const formatDataForTable = (billingData) => {
    return billingData.map((row) => ({
      intactId: row.intact_id,
      accountName: row.customer_name,
      productName: row.product_name,
      netsuiteId: row.netsuite_id,
      finishDate: row.last_run_date,
      totalNumberOfInputs: row.total_number_of_inputs,
      totalQuantity: row.quantity,
    }))
  }

  const columns = [
    { name: 'Intact ID', selector: 'intactId', sortable: true },
    {
      name: 'Account Name',
      selector: 'accountName',
      sortable: true,
      cell: (row) => (
        <CellText title={row.accountName}>{row.accountName}</CellText>
      ),
    },
    {
      name: 'Product',
      selector: 'productName',
      sortable: true,
      cell: (row) => (
        <CellText title={row.productName}>{row.productName}</CellText>
      ),
    },
    { name: 'Netsuite ID', selector: 'netsuiteId', sortable: true },
    {
      name: 'Last Run Date',
      selector: 'finishDate',
      sortable: true,
      cell: (row) => (
        <CellText title={row.finishDate}>{row.finishDate}</CellText>
      ),
    },
    {
      name: 'Number Of Inputs',
      selector: 'totalNumberOfInputs',
      sortable: true,
    },
    { name: 'Included Quantity', selector: 'totalQuantity', sortable: true },
  ]

  const formattedData = formatDataForTable(billingData)

  const filteredData = formattedData.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  const handleDownload = () => {
    setIsDownloading(true)

    const csvContent = [
      columns.map((column) => column.name).join(','),
      ...formattedData.map((row) => Object.values(row).join(',')),
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'billing_details.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)

    setIsDownloading(false)
  }

  return (
    <div style={{ background, color: text, height: '100vh' }}>
      <h1>Billing Details</h1>
      <input
        className='search-input-box'
        type='search'
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <DataTable
        className='data-wrapper'
        columns={columns}
        data={filteredData || []}
        customStyles={{
          headRow: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          headCells: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          rows: {
            style: {
              background: container,
              color: tableText,
            },
          },
          cells: {
            style: {
              background: container,
              color: tableText,
            },
          },
          pagination: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
        }}
      />
      <br />
      <button
        className='form-button'
        onClick={handleDownload}
        disabled={isDownloading}
        style={{
          background: tableheaderBackground,
          color: tableText,
        }}
      >
        {isDownloading ? 'Downloading...' : 'Download'}
      </button>
    </div>
  )
}

export default BillingDetails
