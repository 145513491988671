import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from './ThemeContext'
import '../css/Header.css'
import { AuthContext } from '../auth/AuthProvider'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Switch, Popover, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { urlEndpoints } from '../endpoints/ApiEndpoints'

function Header() {
  const { username, useremail, getUserInfo, oktaAuth } = useContext(AuthContext)
  const { darkMode, toggleDarkMode, background, text } =
    useContext(ThemeContext)
  const [popoverOpen, setpopoverOpen] = useState(false)
  const popoverAnchorRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    getUserInfo()
  }, [])

  const handleOpenMenu = () => {
    setpopoverOpen(true)
  }

  const handleCloseMenu = () => {
    setpopoverOpen(false)
  }

  const handleLogoutConfirmation = () => {
    setpopoverOpen(false)
    const confirmLogout = window.confirm('Are you sure you want to log out?')
    if (confirmLogout) {
      handleLogout()
    }
  }

  const handleLogout = async () => {
    try {
      await oktaAuth.signOut({
        clearTokensBeforeRedirect: true,
        postLogoutRedirectUri: urlEndpoints.baseLogoutPage,
      })
      navigate('/logout')
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return (
    <div>
      <div
        style={{ color: text, cursor: 'pointer' }}
        onClick={handleOpenMenu}
        ref={popoverAnchorRef}
      >
        <AccountCircleIcon className='account-icon' />
      </div>

      <Popover
        open={popoverOpen}
        onClose={handleCloseMenu}
        anchorEl={popoverAnchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: darkMode ? '#1a1d1f' : '#808080',
            color: text,
          },
        }}
      >
        <MenuItem style={{ color: darkMode ? '#cf8700' : '#C70039' }}>
          {username}
        </MenuItem>
        <MenuItem style={{ color: darkMode ? '#cf8700' : '#C70039' }}>
          {useremail.toLowerCase()}
        </MenuItem>
        <MenuItem style={{ color: text }}>
          <Switch checked={darkMode} onChange={toggleDarkMode} />
          {darkMode ? 'Dark Mode' : 'Light Mode'}
        </MenuItem>
        <MenuItem
          style={{
            color: text,
          }}
          onClick={handleLogoutConfirmation}
        >
          <ExitToAppIcon />
          <span>Logout</span>
        </MenuItem>
      </Popover>
    </div>
  )
}

export default Header
