import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../auth/AuthProvider'
import { Table } from 'reactstrap'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useTheme } from '../../components/ThemeContext'

const AppendJobStatus = ({ accountId, jobId, accountCode }) => {
  const {
    background,
    text,
    tableBackground,
    tableText,
    container,
    containerHeader,
    tableheaderBackground,
  } = useTheme()
  const { authToken } = useContext(AuthContext)
  const [data, setData] = useState([])
  const token = authToken

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  useEffect(() => {
    axios
      .get(urlEndpoints.getAppendInstancesJobStatus(accountCode, jobId), {
        headers,
      })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <div className='users-container'>
      <Card style={{ background: tableheaderBackground, color: text }} inverse>
        <CardHeader style={{ background: tableheaderBackground, color: text }}>
          <div className='custom-card-head-style'>
            <h4>Job Status</h4>{' '}
          </div>
        </CardHeader>
        <CardBody
          style={{ background: tableheaderBackground, color: tableText }}
        >
          <Table id='users' bordered dark>
            {/* Check if data is an array before using map */}
            {Array.isArray(data) &&
              data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th style={{ background: tableBackground, color: text }}>
                      Status
                    </th>
                    <th style={{ background: tableBackground, color: text }}>
                      {item.jobinfo.status}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ background: tableBackground, color: text }}>
                      Last Start Time
                    </th>
                    <th style={{ background: tableBackground, color: text }}>
                      {item.jobinfo.lastStartTime}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ background: tableBackground, color: text }}>
                      Total Duration
                    </th>
                    <th style={{ background: tableBackground, color: text }}>
                      {item.jobinfo.totalDuration} minutes
                    </th>
                  </tr>
                  <tr>
                    <th style={{ background: tableBackground, color: text }}>
                      Run Id
                    </th>
                    <th style={{ background: tableBackground, color: text }}>
                      <Link
                        style={{
                          backgroundColor: 'transparent',
                          color: 'rgb(79, 190, 255)',
                        }}
                        className='account-link'
                        to={urlEndpoints.getJobStatusPage(
                          accountId,
                          jobId,
                          item.jobinfo.execId,
                        )}
                      >
                        {item.jobinfo.execId}
                      </Link>
                    </th>
                  </tr>
                </React.Fragment>
              ))}
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default AppendJobStatus
