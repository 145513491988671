import React, { useContext, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap'
import { useTheme } from '../../components/ThemeContext'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import axios from 'axios'

const OutSource = ({
  modal,
  toggle,
  jobId,
  arn,
  prefix,
  s3,
  source_type,
  bucket_name,
  account_code,
  job_name,
}) => {
  const { authToken } = useContext(AuthContext)

  const [activeTab, setActiveTab] = useState(
    source_type === 'sftp' ? 'SFTP' : 'S3',
  )
  const [s3_name, setS3Name] = useState(source_type === 's3' ? s3 : '')
  const [sftp_name, setSftpName] = useState(bucket_name || '')
  const [prefix_name, setPrefixName] = useState(
    source_type === 's3' ? prefix : '',
  )
  const [arn_name, setARNName] = useState(source_type === 's3' ? arn : '')
  const [sftp_prefix_name, sftp_setPrefixName] = useState(
    `${account_code}/${job_name}/outbound`,
  )
  const [sftp_arn_name, sftp_setARNName] = useState(
    source_type === 'sftp' ? arn : '',
  )
  const [changesSaved, setChangesSaved] = useState(false)

  const { background, text, root, tableheaderBackground, container } =
    useTheme()

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleS3Submit = async () => {
    if (!s3_name.trim() || !prefix_name.trim()) {
      alert('Please fill in all required fields.')
      return
    }

    try {
      const formData = {
        source_type: 's3',
        s3: s3_name,
        prefix: prefix_name,
        arn: arn_name,
      }
      await axios.post(urlEndpoints.postOutputSourceS3(jobId), formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        window.location.reload()
      }, 2000)
      toggle()
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  const handleSFTPSubmit = async () => {
    if (!sftp_name.trim() || !sftp_prefix_name.trim()) {
      alert('Please fill in all required fields.')
      return
    }

    try {
      const formData = {
        source_type: 'sftp',
        s3: sftp_name,
        prefix: sftp_prefix_name,
        arn: sftp_arn_name,
      }
      await axios.post(urlEndpoints.postOutputSourceS3(jobId), formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        window.location.reload()
      }, 2000)
      toggle()
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error)
    }
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: '60%' }}>
        <ModalHeader
          style={{ background: tableheaderBackground, color: text }}
          toggle={toggle}
        >
          Output Source
        </ModalHeader>
        <ModalBody
          style={{ background: root, color: text }}
          className='modal-dark'
        >
          <Nav style={{ background: root, color: text }} tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'S3' ? 'active' : ''}
                onClick={() => toggleTab('S3')}
              >
                S3
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'SFTP' ? 'active' : ''}
                onClick={() => toggleTab('SFTP')}
              >
                SFTP
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            style={{ background: root, color: text }}
            activeTab={activeTab}
          >
            <TabPane style={{ background: root, color: text }} tabId='S3'>
              <br />
              <Table id='users' bordered hover dark>
                <thead>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      S3 Bucket Name
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <input
                        type='text'
                        value={s3_name}
                        onChange={(e) => setS3Name(e.target.value)}
                        disabled
                      />
                    </th>
                  </tr>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      Prefix
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <input
                        type='text'
                        value={prefix_name}
                        onChange={(e) => setPrefixName(e.target.value)}
                        disabled
                      />
                    </th>
                  </tr>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      ARN
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <input
                        type='text'
                        value={arn_name}
                        onChange={(e) => setARNName(e.target.value)}
                        disabled
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{/* Add your other rows here if needed */}</tbody>
              </Table>
            </TabPane>

            <TabPane tabId='SFTP'>
              <br />
              <Table id='users' bordered hover dark>
                <thead>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      S3 Bucket Name
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <span>{sftp_name}</span>
                    </th>
                  </tr>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      Prefix
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <span>{sftp_prefix_name}</span>
                    </th>
                  </tr>
                  <tr className='labels'>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      ARN
                    </th>
                    <th
                      style={{
                        background: container,
                        color: text,
                        padding: '10px',
                        border: `2px solid ${root}`,
                      }}
                    >
                      <input
                        type='text'
                        value={sftp_arn_name}
                        onChange={(e) => sftp_setARNName(e.target.value)}
                        disabled
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{/* Add your other rows here if needed */}</tbody>
              </Table>
            </TabPane>
          </TabContent>
        </ModalBody>

        <ModalFooter style={{ background: tableheaderBackground, color: text }}>
          {activeTab === 'S3' && (
            <Button color='primary' onClick={handleS3Submit} disabled={true}>
              Submit for S3
            </Button>
          )}
          {activeTab === 'SFTP' && (
            <Button color='primary' onClick={handleSFTPSubmit}>
              Submit for SFTP
            </Button>
          )}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '50px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default OutSource
