import React from 'react'
import '../css/BaseLayout.css'
import SideBar from '../components/SideBar'
import verisklogo from '../static_assets/verisk_logo.png'
import appendlogo from '../static_assets/append_admin_logo.png'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import { useTheme } from '../components/ThemeContext'

export default function BaseLayout() {
  const { darkMode, background, text } = useTheme()
  const sidebarBackground = darkMode ? '#1a1d1f' : '#808080'

  return (
    <div
      style={{
        backgroundColor: background,
        color: text,
      }}
    >
      <div
        id='container'
        style={{
          backgroundColor: background,
          color: text,
        }}
      >
        <div
          id='sidebar'
          style={{ backgroundColor: sidebarBackground, color: text }}
        >
          <div
            id='append-logo'
            style={{ backgroundColor: sidebarBackground, color: text }}
          >
            <img
              src={appendlogo}
              alt='verisk logo'
              style={{ backgroundColor: sidebarBackground, color: text }}
            />
          </div>
          <div
            id='nav-icons'
            style={{ backgroundColor: sidebarBackground, color: text }}
          >
            <SideBar />
          </div>
          <div
            id='verisk-logo'
            style={{ backgroundColor: sidebarBackground, color: text }}
          >
            <img
              src={verisklogo}
              alt='verisk logo'
              style={{ backgroundColor: sidebarBackground, color: text }}
            />
          </div>
        </div>

        <div id='main'>
          <div id='headbar'>
            <Header />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
