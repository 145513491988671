import React, { useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import axios from 'axios'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import { Label } from 'reactstrap'
import { InputNumber } from 'primereact/inputnumber'
import DeleteIcon from '@mui/icons-material/Delete'
import '../../css/EditUserForm.css'
import { ButtonGroup } from '@mui/material'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'

function CreateActiveInstance() {
  const urlParams = useParams()
  const accountId = urlParams.accountId
  const history = useNavigate()
  const { authToken } = useContext(AuthContext)
  const [name, setName] = useState('')
  const [isEnableToggled, setIsEnableToggled] = useState(false)
  const [isValidationToggled, setIsValidationToggled] = useState(false)
  const [isMonitorToggled, setIsMonitorToggled] = useState(false)
  const [signalToggle, setSignalToggle] = useState(false)
  const [isDiscriptiveDataToggled, setIsDiscriptiveDataToggled] =
    useState(false)
  const [isBatchAppendToggled, setIsBatchAppendToggled] = useState(false)
  const [isHomebuyers, setIsHomebuyers] = useState(false)
  const [isAutoSales, setIsAutoSales] = useState(false)

  const [mortgage_lookback, setmortgage_lookback] = useState(90)
  const [auto_sales_lookback, setauto_sales_lookback] = useState(90)
  const [education_lookback, seteducation_lookback] = useState(90)
  const [insurance_lookback, setinsurance_lookback] = useState(90)
  const [real_estate_lookback, setreal_estate_lookback] = useState(90)

  const [mortgage_frequency, setmortgage_frequency] = useState(4)
  const [auto_sales_frequency, setauto_sales_frequency] = useState(4)
  const [education_frequency, seteducation_frequency] = useState(4)
  const [insurance_frequency, setinsurance_frequency] = useState(4)
  const [real_estate_frequency, setreal_estate_frequency] = useState(4)

  const [monitoring_checkboxes, monitoring_setCheckboxes] = useState([
    { name: 'Monday', checked: true },
    { name: 'Tuesday', checked: true },
    { name: 'Wednesday', checked: true },
    { name: 'Thursday', checked: true },
    { name: 'Friday', checked: true },
    { name: 'Saturday', checked: true },
    { name: 'Sunday', checked: true },
  ])
  const [education_checkboxes, education_setCheckboxes] = useState([
    { name: 'Overall', checked: true },
    { name: 'Education General', checked: true },
    { name: 'Jobs General', checked: true },
  ])
  const [summary_checkboxes, summary_setCheckboxes] = useState([
    { name: 'Input Summary', checked: true },
    { name: 'Output Summary', checked: true },
  ])

  const [loopback_checkboxes, loopback_setCheckboxes] = useState([
    { name: '1 Day', checked: true },
    { name: '7 Days', checked: true },
  ])
  const [insurance_checkboxes, insurance_setCheckboxes] = useState([
    { name: 'Overall', checked: true },
    { name: 'General', checked: true },
    { name: 'Auto', checked: true },
    { name: 'Home', checked: true },
    { name: 'Health', checked: true },
    { name: 'Life', checked: true },
  ])
  const [health_checkboxes, health_setCheckboxes] = useState([
    { name: 'Health General', checked: true },
    { name: 'Health Medicare General', checked: true },
    { name: 'Health Medicare Supplement', checked: true },
    { name: 'Health Medicare Advantage', checked: true },
    { name: 'Health Short Term', checked: true },
    { name: 'Health Dental', checked: true },
    { name: 'Health Disability', checked: true },
  ])
  const [mortgage_checkboxes, mortgage_setCheckboxes] = useState([
    { name: 'Overall', checked: true },
    { name: 'General', checked: true },
    { name: 'Purchase', checked: true },
    { name: 'Refinance', checked: true },
    { name: 'HELOC', checked: true },
    { name: 'Reverse', checked: true },
  ])
  const [options_checkboxes, options_setCheckboxes] = useState([
    { name: 'Number Of Events Witnessed', checked: true },
    { name: 'Time of Day Shopping', checked: true },
    { name: 'Past Day Binary Indicator', checked: true },
  ])
  const [enteredEmail, setEnteredEmail] = useState('')
  const [emailList, setEmailList] = useState([])
  const [signal_checkboxes, signal_setCheckboxes] = useState([
    { name: 'Consumer Status (High, Low, Not Seen)', checked: true },
    { name: 'Consumer Indicator Binary (0, 1)', checked: false },
  ])

  const [selectedOption, setSelectedOption] = useState('DELTA_DIRECTIONAL')

  const [append_selectedOption, append_setSelectedOption] =
    useState('ATTRIBUTES')

  const handleEnableToggle = () => {
    setIsEnableToggled(!isEnableToggled)
  }
  const handleValidationToggle = () => {
    setIsValidationToggled(!isValidationToggled)
  }
  const handleMonitorToggle = () => {
    setIsMonitorToggled(!isMonitorToggled)
  }
  const handleBatchAppendToggle = () => {
    setIsBatchAppendToggled(!isBatchAppendToggled)
  }
  const handleDiscriptiveDataToggle = () => {
    setIsDiscriptiveDataToggled(!isDiscriptiveDataToggled)
  }

  const handleSignalToggle = () => {
    setSignalToggle(!signalToggle)
  }
  const handleHomebuyersCheckboxChange = () => {
    setIsHomebuyers(!isHomebuyers)
  }
  const handleAutoSalesCheckboxChange = () => {
    setIsAutoSales(!isAutoSales)
  }

  const handleEducationCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    education_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleSummaryCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    summary_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleMonitoringCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    monitoring_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleHealthCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    health_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleMortgageCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    mortgage_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleInsuranceCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    insurance_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleSignalCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    signal_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleLoopbackCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    loopback_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleOptionsCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    options_setCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }
  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value)
  }
  const handleAppendDropdownChange = (e) => {
    append_setSelectedOption(e.target.value)
  }
  const handleEmailInputChange = (e) => {
    setEnteredEmail(e.target.value)
  }

  const handleListEmails = () => {
    setEmailList([...emailList, enteredEmail])
    setEnteredEmail('')
  }
  const handleDeleteEmail = (index) => {
    const updatedEmailList = [...emailList]
    updatedEmailList.splice(index, 1)
    setEmailList(updatedEmailList)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const config = {}

    const signal_selectedOptions = {}
    const selectedValues = signal_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Consumer Status (High, Low, Not Seen)') {
          return 'status'
        } else if (checkbox.name === 'Consumer Indicator Binary (0, 1)') {
          return 'indicator'
        }

        return null
      })

      .filter((value) => value !== null)

    signal_selectedOptions['scoresOutput'] = selectedOption
    signal_selectedOptions['packageOutput'] = selectedValues
    config['signal'] = signal_selectedOptions

    if (isDiscriptiveDataToggled) {
      const descriptivedata = {}
      const loopback_checkboxes_value = loopback_checkboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => {
          if (checkbox.name === '1 Day') {
            return '1day'
          } else if (checkbox.name === '7 Days') {
            return '7day'
          }

          return null
        })
        .filter((value) => value !== null)

      const options_checkboxes_values = options_checkboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => {
          if (checkbox.name === 'Number Of Events Witnessed') {
            return 'numberofeventswitnessed'
          } else if (checkbox.name === 'Time of Day Shopping') {
            return 'timeofdayshopping'
          } else if (checkbox.name === 'Past Day Binary Indicator') {
            return 'pastdaybinaryindicator'
          }

          return null
        })
        .filter((value) => value !== null)
      descriptivedata['lookback'] = loopback_checkboxes_value
      descriptivedata['options'] = options_checkboxes_values
      config['descriptiveData'] = descriptivedata
    }

    const recipients = {}
    recipients['recipients'] = emailList
    config['internalAlert'] = recipients

    const frequencyThresholds = {}
    frequencyThresholds['auto_sales'] = auto_sales_frequency
    frequencyThresholds['education'] = education_frequency
    frequencyThresholds['insurance'] = insurance_frequency
    frequencyThresholds['mortgage'] = mortgage_frequency
    frequencyThresholds['real_estate'] = real_estate_frequency
    config['frequencyThresholds'] = frequencyThresholds

    const frequencyLookback = {}
    frequencyLookback['auto_sales'] = auto_sales_lookback
    frequencyLookback['education'] = education_lookback
    frequencyLookback['insurance'] = insurance_lookback
    frequencyLookback['mortgage'] = mortgage_lookback
    frequencyLookback['real_estate'] = real_estate_lookback
    config['eventLookbacks'] = frequencyLookback

    const insurance_selectedValues = insurance_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Overall') {
          return 'insurance'
        } else if (checkbox.name === 'General') {
          return 'insurance_general'
        } else if (checkbox.name === 'Auto') {
          return 'insurance_auto'
        } else if (checkbox.name === 'Home') {
          return 'insurance_home'
        } else if (checkbox.name === 'Health') {
          return 'insurance_health'
        } else if (checkbox.name === 'Life') {
          return 'insurance_life'
        }

        return null
      })
      .filter((value) => value !== null)

    const health_selectedValues = health_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Health General') {
          return 'insurance_health_general'
        } else if (checkbox.name === 'Health Medicare General') {
          return 'insurance_health_medicare_general'
        } else if (checkbox.name === 'Health Medicare Supplement') {
          return 'insurance_health_medicare_supplement'
        } else if (checkbox.name === 'Health Medicare Advantage') {
          return 'insurance_health_medicare_advantage'
        } else if (checkbox.name === 'Health Short Term') {
          return 'insurance_health_short_term'
        } else if (checkbox.name === 'Health Dental') {
          return 'insurance_health_dental'
        } else if (checkbox.name === 'Health Disability') {
          return 'insurance_health_disability'
        }

        return null
      })
      .filter((value) => value !== null)

    const mortgage_selectedValues = mortgage_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Overall') {
          return 'mortgage'
        } else if (checkbox.name === 'General') {
          return 'mortgage_general'
        } else if (checkbox.name === 'Purchase') {
          return 'mortgage_purchase'
        } else if (checkbox.name === 'Refinance') {
          return 'mortgage_refinance'
        } else if (checkbox.name === 'HELOC') {
          return 'mortgage_heloc'
        } else if (checkbox.name === 'Reverse') {
          return 'mortgage_reverse'
        }

        return null
      })
      .filter((value) => value !== null)

    const education_selectedValues = education_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Overall') {
          return 'education'
        } else if (checkbox.name === 'Education General') {
          return 'education_general'
        } else if (checkbox.name === 'Jobs General') {
          return 'jobs'
        }

        return null
      })
      .filter((value) => value !== null)

    let combinedList = [
      ...education_selectedValues,
      ...mortgage_selectedValues,
      ...health_selectedValues,
      ...insurance_selectedValues,
    ]

    if (isHomebuyers) {
      combinedList = [...combinedList, 'real_estate']
    }
    if (isAutoSales) {
      combinedList = [...combinedList, 'auto_sales']
    }

    const batchappends = {}
    if (isBatchAppendToggled) {
      batchappends['clientStatus'] = 'ENABLED'
      batchappends['appendType'] = append_selectedOption
    } else {
      batchappends['clientStatus'] = 'DISABLED'
    }
    config['batchAppends'] = batchappends
    const others = {}
    others['industries'] = combinedList

    if (isMonitorToggled) {
      const monitoring_checkboxes_value = monitoring_checkboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => {
          if (checkbox.name === 'Sunday') {
            return 'Sunday'
          } else if (checkbox.name === 'Monday') {
            return 'Monday'
          } else if (checkbox.name === 'Tuesday') {
            return 'Tuesday'
          } else if (checkbox.name === 'Wednesday') {
            return 'Wednesday'
          } else if (checkbox.name === 'Thursday') {
            return 'Thursday'
          } else if (checkbox.name === 'Friday') {
            return 'Friday'
          } else if (checkbox.name === 'Saturday') {
            return 'Saturday'
          }

          return null
        })
        .filter((value) => value !== null)
      others['monitoringFrequency'] = monitoring_checkboxes_value
    }
    if (isValidationToggled) {
      others['validation'] = 'ENABLE'
    } else {
      others['validation'] = 'DISABLE'
    }

    if (isEnableToggled) {
      others['clientStatus'] = 'ENABLED'
    } else {
      others['clientStatus'] = 'DISABLED'
    }
    const summaryfiles_value = summary_checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        if (checkbox.name === 'Input Summary') {
          return 'input'
        } else if (checkbox.name === 'Output Summary') {
          return 'output'
        }

        return null
      })
      .filter((value) => value !== null)
    others['summaryFiles'] = summaryfiles_value

    config['other'] = others
    const formData = {
      name: name,
      config: config,
    }

    try {
      await axios.post(
        urlEndpoints.createNewInstanceToAccount(accountId),
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )

      history(-1)
    } catch (error) {
      console.error('Error updating user data:', error)
    }
  }

  return (
    <div className='user-edit-form'>
      <h2>New Activate Instance</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Name:</label>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <Label>General</Label>

          <Switch onChange={handleEnableToggle} />
          <Button color='inherit'>Enabled</Button>
          <Switch onChange={handleValidationToggle} />
          <Button color='inherit'>Validation</Button>

          <h4>Monitoring Frequency</h4>
          <Switch onChange={handleMonitorToggle} checked={isMonitorToggled} />
          <Button color='inherit'>Is Monitoring</Button>
          {isMonitorToggled && (
            <div>
              {monitoring_checkboxes.map((checkbox, index) => (
                <div key={index}>
                  <input
                    type='checkbox'
                    class='spaced-label'
                    checked={checkbox.checked}
                    onChange={handleMonitoringCheckboxChange}
                    name={checkbox.name}
                  />
                  <label>{checkbox.name}</label>
                </div>
              ))}
            </div>
          )}
          <br></br>
          <h4>Scores Output</h4>
          <Switch onChange={handleSignalToggle} checked={signalToggle} />
          <Button color='inherit'>Signal Setting</Button>
          {signalToggle && (
            <div>
              {signal_checkboxes.map((checkbox, index) => (
                <div key={index}>
                  <input
                    type='checkbox'
                    class='spaced-label'
                    checked={checkbox.checked}
                    onChange={handleSignalCheckboxChange}
                    name={checkbox.name}
                  />
                  <label>{checkbox.name}</label>
                </div>
              ))}
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value='COMPLETE'>COMPLETE</option>
                <option value='DELTA'>DELTA</option>
                <option value='DELTA_DIRECTIONAL'>DELTA_DIRECTIONAL</option>
              </select>
            </div>
          )}

          <div>
            <h4>Summary Files</h4>
            {summary_checkboxes.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  class='spaced-label'
                  checked={checkbox.checked}
                  onChange={handleSummaryCheckboxChange}
                  name={checkbox.name}
                />
                <label>{checkbox.name}</label>
              </div>
            ))}
          </div>

          <div>
            <br></br>
            <h4>Internal Alert:</h4>
            <input
              type='text'
              value={enteredEmail}
              onChange={handleEmailInputChange}
            />
            <Button variant='contained' onClick={handleListEmails}>
              List Emails
            </Button>
            <div>
              <ul>
                {emailList.map((email, index) => (
                  <li key={index}>
                    {email}
                    <IconButton
                      color='secondary'
                      onClick={() => handleDeleteEmail(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <Switch
            onChange={handleDiscriptiveDataToggle}
            checked={isDiscriptiveDataToggled}
          />
          <Button color='inherit'>Descriptive Data</Button>

          {isDiscriptiveDataToggled && (
            <div>
              <table id='Lookback' style={{ borderSpacing: '50px' }}>
                <thead>
                  <tr>
                    <th>Lookback</th>

                    <th></th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ marginRight: '50px' }}>
                      {loopback_checkboxes.map((checkbox, index) => (
                        <div key={index}>
                          <input
                            type='checkbox'
                            class='spaced-label'
                            checked={checkbox.checked}
                            onChange={handleLoopbackCheckboxChange}
                            name={checkbox.name}
                          />
                          <label>{checkbox.name}</label>
                        </div>
                      ))}
                    </td>

                    <td> </td>
                    <td>
                      {options_checkboxes.map((checkbox, index) => (
                        <div key={index}>
                          <input
                            type='checkbox'
                            class='spaced-label'
                            checked={checkbox.checked}
                            onChange={handleOptionsCheckboxChange}
                            name={checkbox.name}
                          />
                          <label>{checkbox.name}</label>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <div>
            <br></br>
            <h4>Health :</h4>
            {health_checkboxes.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  class='spaced-label'
                  checked={checkbox.checked}
                  onChange={handleHealthCheckboxChange}
                  name={checkbox.name}
                />
                <label>{checkbox.name}</label>
              </div>
            ))}
          </div>

          <div>
            <br></br>
            <h4>Mortgage</h4>
            {mortgage_checkboxes.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  class='spaced-label'
                  checked={checkbox.checked}
                  onChange={handleMortgageCheckboxChange}
                  name={checkbox.name}
                />
                <label>{checkbox.name}</label>
              </div>
            ))}
          </div>

          <div>
            <br></br>
            <h4>Insurance</h4>
            {insurance_checkboxes.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  class='spaced-label'
                  checked={checkbox.checked}
                  onChange={handleInsuranceCheckboxChange}
                  name={checkbox.name}
                />
                <label>{checkbox.name}</label>
              </div>
            ))}
          </div>
          <br></br>
          <h4>Home Buyers</h4>
          <label>
            <input
              type='checkbox'
              class='spaced-label'
              checked={isHomebuyers}
              onChange={handleHomebuyersCheckboxChange}
            />
            Overall
          </label>

          <h4>Auto Sales</h4>
          <label>
            <input
              type='checkbox'
              class='spaced-label'
              checked={isAutoSales}
              onChange={handleAutoSalesCheckboxChange}
            />
            Overall
          </label>

          <div>
            <h4>Education</h4>
            {education_checkboxes.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  class='spaced-label'
                  checked={checkbox.checked}
                  onChange={handleEducationCheckboxChange}
                  name={checkbox.name}
                />
                <label>{checkbox.name}</label>
              </div>
            ))}
          </div>

          <table id='frequency'>
            <thead>
              <tr>
                <th></th>
                <th>Event Lookbacks</th>
                <th>Frequency Thresholds</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Auto Sales</td>
                <td>
                  <InputNumber
                    value={auto_sales_lookback}
                    onValueChange={(e) =>
                      setauto_sales_lookback(e.target.value)
                    }
                    showButtons
                  />
                </td>
                <td>
                  <InputNumber
                    value={auto_sales_frequency}
                    onValueChange={(e) =>
                      setauto_sales_frequency(e.target.value)
                    }
                    showButtons
                  />
                </td>
              </tr>
              <tr>
                <td>Education</td>
                <td>
                  <InputNumber
                    value={education_lookback}
                    onValueChange={(e) => seteducation_lookback(e.target.value)}
                    showButtons
                  />
                </td>
                <td>
                  <InputNumber
                    value={education_frequency}
                    onValueChange={(e) =>
                      seteducation_frequency(e.target.value)
                    }
                    showButtons
                  />
                </td>
              </tr>
              <tr>
                <td>Insurance</td>
                <td>
                  <InputNumber
                    value={insurance_lookback}
                    onValueChange={(e) => setinsurance_lookback(e.target.value)}
                    showButtons
                  />
                </td>
                <td>
                  <InputNumber
                    value={insurance_frequency}
                    onValueChange={(e) =>
                      setinsurance_frequency(e.target.value)
                    }
                    showButtons
                  />
                </td>
              </tr>
              <tr>
                <td>Mortgage</td>
                <td>
                  <InputNumber
                    value={mortgage_lookback}
                    onValueChange={(e) => setmortgage_lookback(e.target.value)}
                    showButtons
                  />
                </td>
                <td>
                  <InputNumber
                    value={mortgage_frequency}
                    onValueChange={(e) => setmortgage_frequency(e.target.value)}
                    showButtons
                  />
                </td>
              </tr>

              <tr>
                <td>Real Estate</td>
                <td>
                  <InputNumber
                    value={real_estate_lookback}
                    onValueChange={(e) =>
                      setreal_estate_lookback(e.target.value)
                    }
                    showButtons
                  />
                </td>
                <td>
                  <InputNumber
                    value={real_estate_frequency}
                    onValueChange={(e) =>
                      setreal_estate_frequency(e.target.value)
                    }
                    showButtons
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <label>Batch Appends</label>
          <Switch onChange={handleBatchAppendToggle} />
          <Button color='inherit'>Enabled</Button>
          {isBatchAppendToggled && (
            <div>
              <select
                value={append_selectedOption}
                onChange={handleAppendDropdownChange}
              >
                <option value='ATTRIBUTES'>ATTRIBUTES</option>
              </select>
            </div>
          )}
        </div>
        <ButtonGroup>
          <Button variant='contained' color='primary' type='submit'>
            Save
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history(-1)
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </form>
    </div>
  )
}

export default CreateActiveInstance
