import '../css/Billing.css'
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/AuthProvider'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { useTheme } from './ThemeContext'
import Tooltip from '@mui/material/Tooltip'

function BillingPage() {
  const { authToken } = useContext(AuthContext)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [company, setCompany] = useState('')
  const [billingType, setBillingType] = useState('Billing')
  const [companyName, setCompanyName] = useState([])
  const [billingTypes] = useState(['Billing'])
  const token = authToken
  const navigate = useNavigate()
  const { background, text, container, tableText, tableheaderBackground } =
    useTheme()

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(urlEndpoints.getAllCompanies, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await response.json()
        const customerNames = data.map((company) => company.customer_name)
        setCompanyName(customerNames)
      } catch (error) {
        console.error('Error fetching companies:', error)
      }
    }

    fetchCompanies()
  }, [token])

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value
    const today = new Date().toISOString().split('T')[0]
    if (selectedDate > today) {
      alert('Start date cannot exceed present date.')
    } else {
      setStartDate(selectedDate)
    }
  }

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value
    const today = new Date().toISOString().split('T')[0]
    if (selectedDate <= startDate) {
      alert('End date should be after the start date.')
    } else if (selectedDate > today) {
      alert('End date cannot exceed present date.')
    } else {
      setEndDate(selectedDate)
    }
  }

  const handleGenerate = () => {
    const dataToPass = {
      startDate,
      endDate,
      company,
    }
    navigate('/oidc/callback/billing/BillingDetails', { state: dataToPass })
  }

  return (
    <div style={{ background, color: text }}>
      <h2>Billing Report Generation</h2>
      <div className='cardbill'>
        <form
          className='form'
          style={{ background: container, color: tableText }}
        >
          <div>
            <h5>Order Count Report</h5>
          </div>
          <div>
            <label
              className='form-label'
              style={{ background: container, color: tableText }}
            >
              From:
            </label>
            <input
              className={`form-input ${!startDate && 'invalid'}`}
              type='date'
              value={startDate}
              onChange={handleStartDateChange}
              required
            />
            {!startDate && (
              <PriorityHighIcon
                className='alert-symbol'
                style={{ color: 'red' }}
              />
            )}
          </div>
          <div>
            <label
              className='form-label'
              style={{ background: container, color: tableText }}
            >
              To:
            </label>
            <input
              className={`form-input ${!endDate && 'invalid'}`}
              type='date'
              value={endDate}
              onChange={handleEndDateChange}
              required
            />
            {!endDate && (
              <PriorityHighIcon
                className='alert-symbol'
                style={{ color: 'red' }}
              />
            )}
          </div>
          <div>
            <label
              className='form-label'
              style={{ background: container, color: tableText }}
            >
              Company:
            </label>
            <select
              className={`form-input ${!company && 'invalid'}`}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            >
              <option value=''>Select a company</option>
              {companyName.map((companyName) => (
                <option key={companyName} value={companyName}>
                  {companyName}
                </option>
              ))}
            </select>
            {!company && (
              <PriorityHighIcon
                className='alert-symbol'
                style={{ color: 'red' }}
              />
            )}
          </div>
          <div>
            <label
              className='form-label'
              style={{ background: container, color: tableText }}
            >
              Type:
            </label>
            <select
              className='form-input'
              value={billingType}
              onChange={(e) => setBillingType(e.target.value)}
            >
              {billingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </form>

        <br />
        <div>
          <Tooltip
            title='Generate the billing report for the selected time period and company'
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: tableheaderBackground,
                  color: text,
                  fontSize: '14px',
                  border: '2px solid #1a1d1f',
                  '& .MuiTooltip-arrow': {
                    color: tableheaderBackground,
                  },
                },
              },
            }}
          >
            <span>
              <button
                className='form-button'
                type='button'
                style={{ background: tableheaderBackground, color: tableText }}
                onClick={handleGenerate}
                disabled={!startDate || !endDate || !company}
              >
                Generate
              </button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default BillingPage
