import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'
import axios from 'axios'
import '../../css/CreateNewAccount.css'
import { AuthContext } from '../../auth/AuthProvider'
import { urlEndpoints } from '../../endpoints/ApiEndpoints'
import { useTheme } from '../../components/ThemeContext'

const CreateNewAccount = () => {
  const history = useNavigate()
  const { authToken } = useContext(AuthContext)
  const [enableActivate, setEnableActivate] = useState(false)
  const [enableGuardian, setEnableGuardian] = useState(false)
  const [accountCode, setAccountCode] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [setTrayioId] = useState('')
  const [error, setError] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [formValid, setFormValid] = useState(false)
  const [changesSaved, setChangesSaved] = useState(false)
  const {
    background,
    text,
    tableBackground,
    tableText,
    tableheaderBackground,
  } = useTheme()
  const [identityCheckboxes, setIdentityCheckboxes] = useState([])
  const [attributeCheckboxes, setAttributeCheckboxes] = useState([])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(urlEndpoints.getProducts, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })

        const products = response.data.product_name

        const identityCheckboxes = products.identity
          ? products.identity.map((item) => ({
              name: item,
              checked: false,
            }))
          : []

        const attributeCheckboxes = products.attributes
          ? products.attributes.map((item) => ({
              name: item,
              checked: false,
            }))
          : []

        setIdentityCheckboxes(identityCheckboxes)
        setAttributeCheckboxes(attributeCheckboxes)
      } catch (error) {
        console.error('Error fetching product data:', error)
      }
    }

    fetchProducts()
  }, [authToken])

  useEffect(() => {
    const isFormValid =
      isValidAccountCode(accountCode) &&
      companyName.trim() !== '' &&
      ((enableActivate &&
        identityCheckboxes.some((checkbox) => checkbox.checked)) ||
        (enableGuardian &&
          attributeCheckboxes.some((checkbox) => checkbox.checked)))

    setFormValid(isFormValid)
  }, [
    accountCode,
    companyName,
    enableActivate,
    enableGuardian,
    identityCheckboxes,
    attributeCheckboxes,
  ])

  const handleFormSubmit = async () => {
    let products = {}

    if (enableActivate) {
      const identity_selectedValues = identityCheckboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.name)

      if (identity_selectedValues.length > 0) {
        products['identity'] = identity_selectedValues
      } else {
        window.alert('Please select at least one subproduct for Identity.')
        return
      }
    }

    if (enableGuardian) {
      const attributes_selectedValues = attributeCheckboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.name)

      if (attributes_selectedValues.length > 0) {
        products['attributes'] = attributes_selectedValues
      } else {
        window.alert('Please select at least one subproduct for Attributes.')
        return
      }
    }

    if (!enableActivate && !enableGuardian) {
      window.alert(
        'Please select at least one product (Identity or Attributes).',
      )
      return
    }

    const formData = {
      code: accountCode,
      company: companyName,
      products: products,
    }

    try {
      await axios.post(urlEndpoints.postCreateAccount, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })

      setChangesSaved(true)
      setTimeout(() => {
        setChangesSaved(false)
        history(-1)
      }, 3000)
    } catch (error) {
      console.error('Error updating user data:', error)
      alert(error.response.data.Message)
    }
  }

  const handleIdentityCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    setIdentityCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const handleAttributeCheckboxChange = (event) => {
    const { name: checkboxName, checked } = event.target
    setAttributeCheckboxes((prevValues) =>
      prevValues.map((checkbox) =>
        checkbox.name === checkboxName ? { ...checkbox, checked } : checkbox,
      ),
    )
  }

  const isValidAccountCode = (code) => {
    const pattern = new RegExp(
      '^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$',
    )
    return pattern.test(code)
  }

  const handleInputChange = (event) => {
    const value = event.target.value
    if (!isValidAccountCode(value)) {
      setError('Invalid account code format')
    } else {
      setError('')
    }
    setAccountCode(value)
  }

  const handleCompanyNameChange = (event) => {
    const value = event.target.value
    setCompanyName(value)

    if (value.trim() === '') {
      setCompanyNameError('Company name cannot be empty')
    } else {
      setCompanyNameError('')
    }
  }

  return (
    <>
      <div style={{ background, color: text }} className='new-account-page'>
        <Card
          style={{ background: tableheaderBackground, color: text }}
          inverse
        >
          <CardHeader
            style={{ background: tableheaderBackground, color: text }}
          >
            <div>
              <h4 style={{ textAlign: 'center' }}>Create A New Account</h4>
            </div>
          </CardHeader>
          <CardBody style={{ background: tableBackground, color: tableText }}>
            <Label for='account-code' sm={2}>
              <h4>Account Code</h4>
            </Label>
            <Col sm={8}>
              <Input
                id='account-code'
                name='account-code'
                placeholder='Enter Account Code'
                type='text'
                value={accountCode}
                onChange={handleInputChange}
              />
              {error && <p className='text-danger'>{error}</p>}
            </Col>

            <Label for='company-inp' sm={2}>
              <h4>Company</h4>
            </Label>
            <Col sm={8}>
              <Input
                id='company-inp'
                name='company'
                placeholder='Enter Company Name '
                type='text'
                value={companyName}
                onChange={handleCompanyNameChange}
              />
              {companyNameError && (
                <p className='text-danger'>{companyNameError}</p>
              )}
            </Col>

            <Label for='trayio-inp' sm={2}>
              <h4>Trayio ID</h4>
            </Label>
            <Col sm={8}>
              <Input
                disabled
                id='trayio-inp'
                name='trayio'
                placeholder='Enter Trayio ID'
                type='text'
                onChange={(event) => {
                  setTrayioId(event.target.value)
                }}
              />
            </Col>

            <Col>
              <Label
                style={{ background: tableBackground, color: tableText }}
                for='products-inp'
                sm={2}
              >
                <h4>Products</h4>
              </Label>
            </Col>

            <Row>
              <div
                style={{ background: tableBackground, color: tableText }}
                className='parent-checkbox'
              >
                <Label
                  style={{ background: tableBackground, color: tableText }}
                >
                  <Input
                    type='checkbox'
                    onChange={() => setEnableActivate(!enableActivate)}
                    checked={enableActivate}
                  />
                  <span
                    style={{ background: tableBackground, color: tableText }}
                  >
                    Identity
                  </span>
                </Label>
                {enableActivate && (
                  <div className='child-checkboxes'>
                    {identityCheckboxes.map((checkbox, index) => (
                      <div key={index} className='child-checkbox'>
                        <Input
                          type='checkbox'
                          checked={checkbox.checked}
                          onChange={handleIdentityCheckboxChange}
                          name={checkbox.name}
                        />
                        <Label
                          style={{
                            background: tableBackground,
                            color: tableText,
                          }}
                        >
                          {checkbox.name}
                        </Label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Row>

            <Row>
              <div className='parent-checkbox'>
                <Label
                  style={{ background: tableBackground, color: tableText }}
                >
                  <Input
                    type='checkbox'
                    onChange={() => setEnableGuardian(!enableGuardian)}
                    checked={enableGuardian}
                  />
                  <span
                    style={{ background: tableBackground, color: tableText }}
                  >
                    Attributes
                  </span>
                </Label>
                {enableGuardian && (
                  <div className='child-checkboxes'>
                    {attributeCheckboxes.map((checkbox, index) => (
                      <div key={index} className='child-checkbox'>
                        <Input
                          type='checkbox'
                          checked={checkbox.checked}
                          onChange={handleAttributeCheckboxChange}
                          name={checkbox.name}
                        />
                        <Label
                          style={{
                            background: tableBackground,
                            color: tableText,
                          }}
                        >
                          {checkbox.name}
                        </Label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Row>
            <div className='buttons-section'>
              <ButtonGroup>
                <Button
                  color='primary'
                  className='save-button'
                  onClick={handleFormSubmit}
                  disabled={!formValid}
                >
                  Save
                </Button>
                <Button className='discard-button' onClick={() => history(-1)}>
                  Discard
                </Button>
              </ButtonGroup>
            </div>
          </CardBody>
        </Card>
      </div>
      {changesSaved && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: '9999',
          }}
        >
          Changes saved successfully!
        </div>
      )}
    </>
  )
}

export default CreateNewAccount
