import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { urlEndpoints } from '../endpoints/ApiEndpoints'
import axios from 'axios'
import { AuthContext } from '../auth/AuthProvider'
import { useTheme } from './ThemeContext'
import styled from 'styled-components'

const CellText = styled.div`
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

function Job() {
  const { authToken } = useContext(AuthContext)
  const { background, text, containerHeader, container, tableText } = useTheme()

  const [searchTerm, setSearchTerm] = useState('')
  const [jobsData, setJobsData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(urlEndpoints.getAllJobs, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })

        const formattedData = formatDataForTable(response.data)
        setJobsData(formattedData)
      } catch (error) {
        console.error('Error:', error.message)
      } finally {
        setLoading(false)
      }
    }

    if (authToken) {
      fetchData()
    }
  }, [authToken])

  const formatDataForTable = (rowData) => {
    return rowData.map((row) => ({
      jobId: row.job_id,
      id: row.execution_id ? row.execution_id.toString() : '',
      account: row.account,
      product: row.product,
      inputFile: row.input_file_name || '',
      createdAt: row.updated_date || '',
      status: row.execution_status,
      totalDuration: row.time_duration_minutes
        ? `${row.time_duration_minutes} minutes`
        : '',
    }))
  }

  const columns = [
    {
      name: 'ID',
      selector: 'jobId',
      sortable: true,
      sortFunction: (a, b) => parseInt(a.jobId) - parseInt(b.jobId),
      cell: (row) => (
        <Link
          className='account-link'
          to={urlEndpoints.getJobStatusPage(row.account, row.jobId, row.id)}
          state={{ jobDetails: row }}
        >
          {row.jobId}
        </Link>
      ),
    },
    { name: 'Run ID', selector: 'id', sortable: true },
    {
      name: 'Account',
      selector: 'account',
      sortable: true,
      cell: (row) => <CellText title={row.account}>{row.account}</CellText>,
    },
    {
      name: 'Product',
      selector: 'product',
      sortable: true,
      cell: (row) => <CellText title={row.product}>{row.product}</CellText>,
    },
    {
      name: 'Input File',
      selector: 'inputFile',
      sortable: true,
      cell: (row) => <CellText title={row.inputFile}>{row.inputFile}</CellText>,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      cell: (row) => <CellText title={row.createdAt}>{row.createdAt}</CellText>,
    },
    { name: 'Status', selector: 'status', sortable: true },
    { name: 'Total Duration', selector: 'totalDuration', sortable: true },
  ]

  const filteredData = jobsData.filter((row) => {
    const { jobId, id, account, product, status } = row
    const searchTermLower = searchTerm.toLowerCase()

    return (
      jobId.toString().includes(searchTermLower) ||
      id.toString().includes(searchTermLower) ||
      account.toLowerCase().includes(searchTermLower) ||
      product.toLowerCase().includes(searchTermLower) ||
      status.toLowerCase().includes(searchTermLower)
    )
  })

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div style={{ background, color: text }}>
      <h1>Job History</h1>
      <input
        className='search-input-box'
        type='search'
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <DataTable
        className='data-wrapper'
        columns={columns}
        data={filteredData}
        direction='auto'
        fixedHeaderScrollHeight='300px'
        pagination
        responsive
        subHeaderAlign='left'
        subHeaderWrap
        customStyles={{
          headRow: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          headCells: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
          rows: {
            style: {
              background: container,
              color: tableText,
            },
          },
          cells: {
            style: {
              background: container,
              color: tableText,
            },
          },
          pagination: {
            style: {
              background: containerHeader,
              color: tableText,
            },
          },
        }}
      />
    </div>
  )
}

export default Job
