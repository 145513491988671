let apiRootUrl = process.env.REACT_APP_DOMAIN_URL

const urlEndpoints = {
  allAccountsList: apiRootUrl + '/admin/accounts',
  getAllJobs: apiRootUrl + '/admin/jobs',
  getAccountCustomer: apiRootUrl + '/admin/accounts',
  getFileFormatDelimiter: apiRootUrl + '/admin/fileformats',
  getallUsers: apiRootUrl + '/admin/users',
  getAllFields: (productName) =>
    `${apiRootUrl}/admin/jobs/default/products/${productName}/fields`,
  postCreateAccount: apiRootUrl + '/admin/accounts',
  fieldvalidation: apiRootUrl + '/admin/field_validations',
  defaultLayoutHeaders: (productName) =>
    apiRootUrl + `/admin/products/${productName}/layouts/default`,
  getAllCompanies: apiRootUrl + '/admin/companies',
  getProducts: apiRootUrl + '/admin/products',
  getBillingDetails: apiRootUrl + '/admin/billing',
  getCustomLayoutDetails: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/input_info`,
  postJobStatus: (jobId) => `${apiRootUrl}/admin/jobs/${jobId}/status`,
  postOutputConfigStatus: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/output`,
  postNotification: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/output_info`,
  postCustomFileDetails: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/input_info`,
  postCustomLayout: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/input`,
  getOutputHeaderDetails: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/output_info`,
  postSelectFields: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/fields`,
  postDefaultLayout: (jobId, productName) =>
    `${apiRootUrl}/admin/jobs/${jobId}/products/${productName}/layouts/default`,
  postPreProcessing: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/pre_processing`,
  postPostProcessing: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/post_processing`,
  getPreProcessing: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/pre_processing`,
  getPostProcessing: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/post_processing`,
  postInputSourceS3: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/input_info`,
  postOutputSourceS3: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/configurations/output_info`,
  editAppendToAccount: (accountId, jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}`,
  getSelectField: (jobId, productName) =>
    `${apiRootUrl}/admin/jobs/${jobId}/products/${productName}/fields`,
  getJobStatus: (job_id, execution_id) =>
    `${apiRootUrl}/admin/jobs/${job_id}/executions/${execution_id}/status`,
  getReportLog: (job_id, execution_id) =>
    `${apiRootUrl}/admin/jobs/${job_id}/executions/${execution_id}`,
  getAppendInstancesDetails: (accountId, jobId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/jobs/${jobId}/`,
  getAppendInstancesDetailNameandEmail: (accountId, jobId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/jobs/${jobId}/summary`,
  getOutputConfigHeaders: (jobId) =>
    `${apiRootUrl}/admin/jobs/${jobId}/layouts/output_info/`,
  getAppendInstancesJobStatus: (accountId, jobId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/jobs/${jobId}`,
  getAppendList: (salesforceId) =>
    `${apiRootUrl}/admin/accounts/${salesforceId}/jobs`,
  getUsersForAccountId: (accountId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/users`,
  navigateToNewUserForm: (accountId) =>
    `/oidc/callback/accounts/${accountId}/users/new`,
  navigateToActiveInstances: (accountId) =>
    `/oidc/callback/activeinstances/${accountId}`,
  navigateToAppends: (accountId) => `/oidc/callback/appends/${accountId}`,
  navigateToEditAccountDetails: (accountId) =>
    `/oidc/callback/accounts/${accountId}/edit`,
  editUserDataForAccountId: (accountId, item) =>
    `/oidc/callback/accounts/${accountId}/users/${item.email}`,
  addNewUserToAccountId: (accountId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/users?account_code=${accountId}`,
  getUserDataWithEmailId: (accountId, email_id) =>
    `${apiRootUrl}/admin/accounts/${accountId}/users/${email_id}`,
  getAccountDetailsForAccountId: (accountId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/`,
  editAccountDetailsWithId: (accountId) =>
    `/oidc/callback/accounts/${accountId}`,
  createNewInstanceToAccount: (accountId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/activate/instances/`,
  createNewAppendToAccount: (saleforceId) =>
    `${apiRootUrl}/admin/accounts/${saleforceId}/jobs`,
  getInstanceInfoForAccount: (accountId, item) =>
    `/oidc/callback/activeinstances/${accountId}/instance/${item.id}`,
  putInstanceInfoDetails: (accountId, instanceId) =>
    `${apiRootUrl}/admin/accounts/${accountId}/activate/instances/${instanceId}/`,
  getAppendInfo: (accountName) => `/oidc/callback/append/${accountName}/`,
  getAppendJobInfo: (accountCode, accountName, jobid) =>
    `/oidc/callback/appendinstances/${accountCode}/${accountName}/instance/${jobid}`,
  getJobStatusPage: (salesforce_id, jobid, executionid) =>
    `/oidc/callback/jobs/${salesforce_id}/${jobid}/${executionid}/jobStatus`,
  getAccountInfoPage: (accountCode, salesforce_id, companyName) =>
    `/oidc/callback/accounts/${accountCode}/${salesforce_id}?additionalData=${companyName}`,

  createNewAccountForm: '/oidc/callback/accounts/new',
  veriskSignout: process.env.REACT_APP_OKTA_SIGNOUT_URL,
  oktaIssuerURL: process.env.REACT_APP_OKTA_ISSUER_URL,
  baseLoginPage: process.env.REACT_APP_OKTA_BASE_URL,
  baseLogoutPage: process.env.REACT_APP_OKTA_LOGOUT_URL,
}

export { urlEndpoints }
